import { CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import enviornment from "../../../../utils/web3/constants/constants";
import { Web3Context } from "../../../../utils/web3/contexts/web3Context";
import {
  createContractInstance,
  createContractWithSigner,
} from "../../../../utils/web3/functions/scMethods";
import BasicPagination from "../../../pagination/BasicPagination";
import {
  DISPUTEManagementActions,
  setLoader,
  updateDisputeAsync,
} from "../../redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

const DisputeTable = () => {
  const { networkDetails } = useContext(Web3Context);

  const [dispDetails, setDispDetails] = useState(false);
  const [dispData] = useState();
  const { isLoading, DISPUTEList, skip, limit, dir, searchBy } = useSelector(
    (state) => state.dispute,
    shallowEqual
  );

  const history = useHistory();
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(DISPUTEManagementActions.setDISPUTEBatchNumber(count));
  };

  const handleSort = (column, dir) => {
    dispatch(DISPUTEManagementActions.setSortDISPUTE({ column, dir }));
  };

  useEffect(
    () => handleSort("", ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClick = (data) => {
    history.push(`/dispute-details/${data._id}`);
  };

  const onAcceptClick = async (dispute) => {
    if (networkDetails.address !== "") {
      let contract = createContractInstance(
        networkDetails.provider,
        enviornment[networkDetails.networkId].dev.P2PAddress,
        enviornment[networkDetails.networkId].dev.P2PABI
      );
      let Owner = await contract.owner();

      if (networkDetails.address.toLowerCase() === Owner.toLowerCase()) {
        dispatch(setLoader(true));
        contract = await createContractWithSigner(
          networkDetails.provider,
          contract
        );
        contract
          .fulfillBuyRequest_Batch([dispute.orderDetails.buyId])
          .then((data) => {
            dispatch(setLoader(false));

            let obj = {
              transactionHash: data.hash,
              disputeId: dispute._id,
            };

            dispatch(updateDisputeAsync(obj));
          })
          .catch((er) => {
            dispatch(setLoader(false));

            toast.error(er.message);
          });
      } else {
        toast.warning("Please connect with Admin wallet.");
      }
    } else {
      toast.error("Please connect your Wallet first.");
    }
  };
  const onRejectClick = async (dispute) => {
    if (networkDetails.address !== "") {
      let contract = createContractInstance(
        networkDetails.provider,
        enviornment[networkDetails.networkId].dev.P2PAddress,
        enviornment[networkDetails.networkId].dev.P2PABI
      );
      let Owner = await contract.owner();

      if (networkDetails.address.toLowerCase() === Owner.toLowerCase()) {
        dispatch(setLoader(true));
        contract = await createContractWithSigner(
          networkDetails.provider,
          contract
        );

        contract
          .cancelBuyRequest_Batch([dispute.orderDetails.buyId])
          .then((data) => {
            dispatch(setLoader(false));

            let obj = {
              transactionHash: data.hash,
              disputeId: dispute._id,
            };

            dispatch(updateDisputeAsync(obj));
          })
          .catch((er) => {
            dispatch(setLoader(false));

            toast.error(er.message);
          });
      } else {
        toast.warning("Please connect with Admin wallet.");
      }
    } else {
      toast.error("Please connect your Wallet first.");
    }
  };

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (DISPUTEList) {
      setPageCount(Math.ceil(DISPUTEList?.recordsTotal / 10));
      setCount(DISPUTEList.recordsTotal);
    }
  }, [offset, pageCount, DISPUTEList]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    dispatch(DISPUTEManagementActions.setDISPUTEBatchNumber(selectedPage));
  };

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "title",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Title{" "}
                    {dir !== "" && searchBy === "title" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "email",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Email
                    {dir !== "" && searchBy === "email" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>

                  <th>TransactionId</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {DISPUTEList?.records && DISPUTEList?.records.length ? (
                  DISPUTEList?.records.map((dispute, index) => (
                    <tr
                      key={index}
                      //
                    >
                      <td>
                        <span className="f-w-500">
                          {skip * limit + (index + 1)}
                        </span>
                      </td>
                      <td
                        className="m_title"
                        onClick={() => handleClick(dispute)}
                        style={{ cursor: "pointer" }}
                      >
                        {dispute.title}
                      </td>
                      <td>{dispute.email}</td>

                      <td>{dispute?.orderDetails?.transactionId}</td>
                      <td>{dispute?.disputeType}</td>
                      <td>
                        {(dispute.disputeType === "pendingBuyerPayment" ||
                          dispute.disputeType === "pendingSellerConfirm") &&
                          dispute.status === "pending" && (
                            <button
                              type="button"
                              className="btn_new btn-sm"
                              onClick={() => {
                                onAcceptClick(dispute);
                              }}
                            >
                              Accept
                            </button>
                          )}{" "}
                        {(dispute.disputeType === "pendingBuyerPayment" ||
                          dispute.disputeType === "pendingSellerConfirm") &&
                          dispute.status === "pending" && (
                            <button
                              type="button"
                              className="btn_new btn-sm"
                              onClick={() => {
                                onRejectClick(dispute);
                              }}
                            >
                              Reject
                            </button>
                          )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
            <div className="col-md-8 text-right mb-5 aspgntn">
              {count > 10 && pageCount > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  color="primary"
                  variant="outlined"
                />
              )}
            </div>
            <div className="col-md-4 mb-5 fn_s_16 text-right">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {DISPUTEList?.recordsTotal} Records
            </div>
          </div>
          {/* {!isLoading && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={DISPUTEList?.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {DISPUTEList?.recordsTotal} Records
              </div>
            </div>
          )} */}
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 text-center mb-10">
              Are you sure want you to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <button
                type="button"
                className="btn_new btn-sm mr-3"
                onClick={deleteClose}
              >
                Close
              </button>
              <button type="button" className="btn_new btn-sm">
                Yes, Delete
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={dispDetails}
        onHide={() => setDispDetails(false)}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">
            Dispute Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <tr className="border-bottom">
              <td>
                <label className="fn_s_16">Dispute Raised On: </label>
              </td>
              <td>
                <span className="color_main f-w-850 ml-0">
                  {moment(dispData?.orderDetails?.createdAt).format(
                    "MMM DD, YYYY"
                  )}
                </span>
              </td>
            </tr>
            <tr className="border-bottom">
              <td>
                <label className="fn_s_16">Subject:</label>
              </td>
              <td>
                <span className="color_main f-w-850 ml-0">
                  {dispData?.title || "-"}
                </span>
              </td>
            </tr>
            <tr className="border-bottom">
              <td>
                <label className="fn_s_16">Email: </label>
              </td>
              <td>
                <span className="color_main f-w-850 ml-0">
                  {dispData?.email || "-"}
                </span>
              </td>
            </tr>
          </table>
          <label className="fn_s_16 mt-2">
            Description:{" "}
            <Form.Control
              as="textarea"
              value={dispData?.description}
              aria-label="With textarea"
              rows="3"
              style={{
                cursor: "default",
                width: "30rem",
                overflow: "auto",
                fontSize: "16px",
              }}
              disabled={true}
              required
              className="p-3 mt-4"
              placeholder="Disput Description"
            />
          </label>
          <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
            <div className="form-group mr-5 mb-1 mt-2">
              <button
                className="btn_new btn-sm mt-5"
                type="button"
                onClick={() => setDispDetails(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DisputeTable;
