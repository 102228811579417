import "bootstrap-daterangepicker/daterangepicker.css";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getAllWithdrawAsync, WithdrawManagementActions } from "../redux";
import WithdrawManagementTable from "./withdrawManagementDetail/withdrawManagementTable";

export function WithdrawManagement() {
  const countryListOptions = [];
  const currency = [];
  const region = [];
  const dispatch = useDispatch();
  const searchWithdrawRef = useRef();

  const {
    refreshWithdrawList,
    searchBy,
    gateway,
    countryList,
    searchText,
    dir,
  } = useSelector((state) => state.withdrawManagement, shallowEqual);

  const [WithdrawFilter, setWithdrawFilter] = useState({
    countryId: null,
    paymentId: null,
    currency: null,
    startDate: null,
    endDate: null,
  });

  const initialValues = {
    countryList: [],
  };

  if (countryList && countryList.length > 0) {
    initialValues.countryList = countryList;
    countryList.forEach((country) => {
      countryListOptions.push({
        value: country._id,
        label: country.name,
      });
    });
  }

  if (countryList && countryList.length > 0) {
    countryList.forEach((curr) => {
      currency.push({
        label: curr.currency,
        value: curr._id,
      });
    });
  }

  if (gateway && gateway.length > 0) {
    gateway.forEach((gateway) => {
      region.push({
        label: gateway.name,
        value: gateway._id,
      });
    });
  }

  useEffect(() => {
    dispatch(WithdrawManagementActions.setSelectedWithdraw(null));
    if (searchWithdrawRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(WithdrawManagementActions.refreshWithdrawList());
        }
      };
      let input = searchWithdrawRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(WithdrawManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (refreshWithdrawList) {
      dispatch(getAllWithdrawAsync(searchBy, searchText, "", WithdrawFilter));
    }
    dispatch(getAllWithdrawAsync(searchBy, searchText, dir, WithdrawFilter));
  }, [
    dispatch,
    refreshWithdrawList,
    searchBy,
    searchText,
    dir,
    WithdrawFilter,
  ]);

  const onSearchTextChange = (e) => {
    dispatch(WithdrawManagementActions.searchTextChange(e.target.value));
  };

  const onResetClick = (e) => {
    setWithdrawFilter({
      ...WithdrawFilter,
      countryId: null,
      paymentId: null,
      currency: null,
      startDate: null,
      endDate: null,
    });
    setStateDate([]);
    dispatch(WithdrawManagementActions.searchTextChange(""));
  };

  const onDateChange = (e) => {
    if (e && e[0]) {
      let date = {
        startDate: e[0]
          .toDate()
          .toISOString()
          .slice(0, 10),
        endDate:
          e.length && e.length === 2
            ? e[1]
                .toDate()
                .toISOString()
                .slice(0, 10)
            : e[0]
                .toDate()
                .toISOString()
                .slice(0, 10),
      };
      setWithdrawFilter({ startDate: date.startDate, endDate: date.endDate });
    }
  };

  const [stateDate, setStateDate] = useState([]);

  return (
    <>
      <Card>
        <CardHeader title="Withdraw Management" />
        <CardHeaderToolbar className="filter_block">
          <Formik
            initialValues={{
              search: "",
              country: "",
              currency: "",
              region: "",
              startDate: "",
              endDate: "",
            }}
          >
            {(formProps) => (
              <Form>
                <div
                  className="form-group mb-0 p-5"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: "15px",
                  }}
                >
                  <div style={{ width: "20rem" }} className="">
                    <label htmlFor="search">Search By</label>
                    <Field
                      name="search"
                      placeholder="Search By"
                      type="text"
                      className="form-control w-100 h-auto"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchWithdrawRef}
                    />
                  </div>

                  <div className="iv_datepicker" style={{ width: "20rem" }}>
                    <label className="w-100">Date picker</label>

                    <DatePicker
                      placeholder="Pick date"
                      format="DD/MM/YYYY"
                      onChange={onDateChange}
                      maxDate={new Date()}
                      name="startDate"
                      value={stateDate}
                      rangeHover
                      range
                    ></DatePicker>
                  </div>
                  <div className="mt-11">
                    <button
                      className="btn_green btn-sm"
                      type="button"
                      onClick={() => {
                        formProps.resetForm();
                        onResetClick();
                        formProps.setFieldValue("startDate", "");
                        formProps.setFieldValue("endDate", "");
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CardHeaderToolbar>
        <WithdrawManagementTable />
      </Card>
    </>
  );
}
