import { OrderManagementMap } from "./OrderManagementAction";

const initialState = {
  isLoading: false,
  OrderList: {},
  refreshOrderList: true,
  selectedOrder: null,
  searchBy: "",
  dir: "",
  countryList: [],
  gateway: [],
  searchText: "",
  skip: 0,
  limit: 10,
  orderDetails: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OrderManagementMap.GET_ALL_Order_START: {
      return {
        ...state,
        isLoading: true,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.GET_ALL_Order_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        OrderList: action.payload,
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.GET_ALL_Order_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.ADD_Order_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case OrderManagementMap.ADD_Order_SUCCESS: {
      return {
        ...state,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.ADD_Order_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.UPDATE_Order_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case OrderManagementMap.UPDATE_Order_SUCCESS: {
      return {
        ...state,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.UPDATE_Order_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.GET_ALL_COUNTRY_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case OrderManagementMap.GET_ALL_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        countryList: action.payload,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.GET_ALL_COUNTRY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        countryList: [],
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.GET_ALL_GATEWAY_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case OrderManagementMap.GET_ALL_GATEWAY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        gateway: action.payload,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.GET_ALL_GATEWAY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        gateway: [],
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.SELECT_Order_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedOrder: action.payload,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        refreshOrderList: true,
        searchText: action.payload,
      };
    }
    case OrderManagementMap.SET_Order_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.REFRESH_Order_LIST: {
      return {
        ...state,
        skip: 0,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.DELETE_Order_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case OrderManagementMap.DELETE_Order_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshOrderList: true,
      };
    }
    case OrderManagementMap.DELETE_Order_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
        refreshOrderList: true,
      };
    }

    case OrderManagementMap.GET_ORDER_DETAILS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case OrderManagementMap.GET_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload,
        refreshOrderList: false,
      };
    }
    case OrderManagementMap.GET_ORDER_DETAILS_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshOrderList: false,
      };
    }
    default:
      return { ...state };
  }
};
