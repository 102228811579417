import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { PaymentMasterManagementActions } from "../../redux";
import ReactPaginate from "react-paginate";

const PaymentMasterTable = () => {
  const {
    isLoading,
    searchBy,
    paymentmasterlist,
    skip,
    limit,
    dir,
  } = useSelector((state) => state.paymentMasterManagement, shallowEqual);
  const history = useHistory();
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(PaymentMasterManagementActions.setPaymentMasterBatchNumber(count));
  };
  const handleSort = (column, dir) => {
    dispatch(
      PaymentMasterManagementActions.setSortPaymentMaster({ column, dir })
    );
  };
  useEffect(
    () => handleSort(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onEditClick = (paymentMasterEdit) => {
    dispatch(
      PaymentMasterManagementActions.setSelectedPaymentMaster(paymentMasterEdit)
    );
    history.push(`/paymentMaster/add-payment-master/${paymentMasterEdit?._id}`);
  };

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (paymentmasterlist) {
      setPageCount(Math.ceil(paymentmasterlist?.recordsTotal / 10));
      setCount(paymentmasterlist.recordsTotal);
    }
  }, [offset, pageCount, paymentmasterlist]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    dispatch(
      PaymentMasterManagementActions.setPaymentMasterBatchNumber(selectedPage)
    );
  };

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "countryName",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Country Name{" "}
                    {dir !== "" && searchBy === "countryName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th>Payment Name</th>

                  <th width="120px">Action</th>
                </tr>
              </thead>
              <tbody>
                {paymentmasterlist.records &&
                paymentmasterlist.records.length ? (
                  paymentmasterlist.records.map((paymentmasterlists, index) => (
                    <tr key={index}>
                      <td>
                        <span className="">{skip * limit + (index + 1)}</span>
                      </td>

                      <td>
                        <span className="">
                          {paymentmasterlists.countryName}
                        </span>
                      </td>
                      <td>{paymentmasterlists.paymentName.join(", ")}</td>
                      <td>
                        <button
                          title="Edit"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                          onClick={() => onEditClick(paymentmasterlists)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
            <div className="col-md-8 text-right mb-5 aspgntn">
              {count > 10 && pageCount > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  color="primary"
                  variant="outlined"
                />
              )}
            </div>
            <div className="col-md-4 mb-5 fn_s_14 text-right">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {paymentmasterlist.recordsTotal} Records
            </div>
          </div>
          {/* {!isLoading && (
            <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={paymentmasterlist.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_14 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {paymentmasterlist.recordsTotal} Records
              </div>
            </div>
          )} */}
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want you to Deactivate ?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <button
                type="button"
                className="btn_new btn-sm mr-3"
                onClick={deleteClose}
              >
                Close
              </button>
              <button type="button" className="btn_new btn-sm">
                Yes, Deactivate
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentMasterTable;
