import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getGeneralSettingAsync } from "../redux/generalSettingApi";
import { Card, CardHeader } from "../../../../_metronic/_partials/controls";
import AddGeneralSetting from "./general_setting_page/addGeneralSetting";

export function GeneralSettingPages() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGeneralSettingAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="General Setting"></CardHeader>
        <AddGeneralSetting />
      </Card>
    </>
  );
}
