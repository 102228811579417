export const CMSManagementMap = {
  GET_ALL_CMS_START: "GET_ALL_CMS_START",
  GET_ALL_CMS_SUCCESS: "GET_ALL_CMS_SUCCESS",
  GET_ALL_CMS_ERROR: "GET_ALL_CMS_ERROR",
  ADD_CMS_START: "ADD_CMS_START",
  ADD_CMS_SUCCESS: "ADD_CMS_SUCCESS",
  ADD_CMS_ERROR: "ADD_CMS_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_CMS_LIST: "REFRESH_CMS_LIST",
  SET_CMS_BATCH_NUMBER: "SET_CMS_BATCH_NUMBER",
  SELECT_CMS_DETAILS: "SELECT_CMS_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  UPDATE_CMS_START: "UPDATE_CMS_START",
  UPDATE_CMS_SUCCESS: "UPDATE_CMS_SUCCESS",
  UPDATE_CMS_ERROR: "UPDATE_CMS_ERROR",
  DELETE_CMS_START: "DELETE_CMS_START",
  DELETE_CMS_SUCCESS: "DELETE_CMS_SUCCESS",
  DELETE_CMS_ERROR: "DELETE_CMS_ERROR",
};

export const CMSManagementActions = {
  getAllCMSStart: (data) => ({
    type: CMSManagementMap.GET_ALL_CMS_START,
    payload: data,
  }),
  getAllCMSSuccess: (data) => ({
    type: CMSManagementMap.GET_ALL_CMS_SUCCESS,
    payload: data,
  }),
  getAllCMSError: (errors) => ({
    type: CMSManagementMap.GET_ALL_CMS_ERROR,
    payload: { errors },
  }),
  deleteCMSStart: (data) => ({
    type: CMSManagementMap.DELETE_CMS_START,
    payload: data,
  }),
  deleteCMSSuccess: (data) => ({
    type: CMSManagementMap.DELETE_CMS_SUCCESS,
    payload: data,
  }),
  deleteCMSError: (errors) => ({
    type: CMSManagementMap.DELETE_CMS_ERROR,
    payload: { errors },
  }),
  addCMSStart: (data) => ({
    type: CMSManagementMap.ADD_CMS_START,
    payload: data,
  }),
  addCMSSuccess: (data) => ({
    type: CMSManagementMap.ADD_CMS_SUCCESS,
    payload: data,
  }),
  addCMSError: (errors) => ({
    type: CMSManagementMap.ADD_CMS_ERROR,
    payload: { errors },
  }),
  updateCMSStart: (data) => ({
    type: CMSManagementMap.UPDATE_CMS_START,
    payload: data,
  }),
  updateCMSSuccess: (data) => ({
    type: CMSManagementMap.UPDATE_CMS_SUCCESS,
    payload: data,
  }),
  updateCMSError: (errors) => ({
    type: CMSManagementMap.UPDATE_CMS_ERROR,
    payload: { errors },
  }),
  getCMSTemplateStart: (data) => ({
    type: CMSManagementMap.GET_ALL_TEMPLATES_START,
    payload: data,
  }),
  getCMSTemplateSuccess: (data) => ({
    type: CMSManagementMap.GET_ALL_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getCMSTemplateError: (errors) => ({
    type: CMSManagementMap.GET_ALL_TEMPLATES_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: CMSManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshCMSList: () => ({ type: CMSManagementMap.REFRESH_CMS_LIST }),
  searchTextChange: (data) => ({
    type: CMSManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedCMS: (data) => ({
    type: CMSManagementMap.SELECT_CMS_DETAILS,
    payload: data,
  }),
  setCMSBatchNumber: (data) => ({
    type: CMSManagementMap.SET_CMS_BATCH_NUMBER,
    payload: data,
  }),
  setSortEmail: (data) => ({
    type: CMSManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),
};
