import { createContext } from "react";

// let a = {
//   loadWeb3: "",
//   loading: "",
//   setLoading: "",
//   networkDetails: "",
//   setNetworkDetails: "",
//   loadBlockChainData: "",
//   ListenAccountChange: "",
//   listenNetworkChange: "",
//   handleConnect: "",
//   resetApp: "",
// };

const Web3Context = createContext();

const Web3Consumer = Web3Context.Consumer;

const Web3Provider = Web3Context.Provider;

export { Web3Context, Web3Consumer, Web3Provider };
