import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { CMSManagementActions } from "./CMSManagementAction";

export const getAllCMSAsync = (searchBy, searchText, dir) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().cmsManagement;
      dispatch(CMSManagementActions.getAllCMSStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}/api/cms/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(CMSManagementActions.getAllCMSSuccess(data.data));
      }
      dispatch(CMSManagementActions.getAllCMSError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(CMSManagementActions.getAllCMSError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addCMSAsync = (values, setSubmitting, resetForm, redirectBack) => {
  return async (dispatch, getState) => {
    try {
      dispatch(CMSManagementActions.addCMSStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/cms/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(CMSManagementActions.addCMSSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(CMSManagementActions.addCMSError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(CMSManagementActions.addCMSError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateCMSAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      let { selectedCMS } = getState().cmsManagement;
      dispatch(CMSManagementActions.updateCMSStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}/api/cms/updateDetails/${selectedCMS._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(CMSManagementActions.updateCMSSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(CMSManagementActions.updateCMSError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(CMSManagementActions.updateCMSError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteCMSAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(CMSManagementActions.deleteCMSStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/cms/delete/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(CMSManagementActions.deleteCMSSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(CMSManagementActions.deleteCMSError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(CMSManagementActions.deleteCMSError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
