import { CountryManagementMap } from "./CountryManagementAction";

const initialState = {
  isLoading: false,
  COUNTRYList: {},
  refreshCOUNTRYList: true,
  selectedCOUNTRY: null,
  searchBy: "",
  dir: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CountryManagementMap.GET_ALL_COUNTRY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CountryManagementMap.GET_ALL_COUNTRY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        COUNTRYList: action.payload,
        refreshCOUNTRYList: false,
      };
    }
    case CountryManagementMap.GET_ALL_COUNTRY_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCOUNTRYList: false,
      };
    }
    case CountryManagementMap.ADD_COUNTRY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CountryManagementMap.ADD_COUNTRY_SUCCESS: {
      return {
        ...state,
        refreshCOUNTRYList: true,
      };
    }
    case CountryManagementMap.ADD_COUNTRY_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCOUNTRYList: false,
      };
    }
    case CountryManagementMap.UPDATE_COUNTRY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CountryManagementMap.UPDATE_COUNTRY_SUCCESS: {
      return {
        ...state,
        refreshCOUNTRYList: true,
      };
    }
    case CountryManagementMap.UPDATE_COUNTRY_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCOUNTRYList: false,
      };
    }
    case CountryManagementMap.GET_ALL_TEMPLATES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CountryManagementMap.GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templates: action.payload,
        refreshCOUNTRYList: true,
      };
    }
    case CountryManagementMap.GET_ALL_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        templates: [],
        refreshCOUNTRYList: false,
      };
    }
    case CountryManagementMap.SELECT_COUNTRY_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedCOUNTRY: action.payload,
      };
    }
    case CountryManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case CountryManagementMap.SET_COUNTRY_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshCOUNTRYList: true,
      };
    }
    case CountryManagementMap.REFRESH_COUNTRY_LIST: {
      return {
        ...state,
        skip: 0,
        refreshCOUNTRYList: true,
      };
    }
    case CountryManagementMap.DELETE_COUNTRY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CountryManagementMap.DELETE_COUNTRY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshCOUNTRYList: true,
      };
    }
    case CountryManagementMap.DELETE_COUNTRY_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCOUNTRYList: false,
      };
    }
    case CountryManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }
    default:
      return { ...state };
  }
};
