const supportedChains = [
  {
    name: "ETH-mainnet",
    chain_id: "0x1",
    network_id: 1,
    rpc_url: "",
    network_type: "ethereum",
    isChainValid: true,
  },
  {
    name: "Goerli Test Network",
    chain_id: "0x5",
    network_id: 5,
    rpc_url: "",
    network_type: "ethereum",
    isChainValid: true,
  },
];

export default supportedChains;
