export const PaymentManagementMap = {
  GET_ALL_PAYMENT_START: "GET_ALL_PAYMENT_START",
  GET_ALL_PAYMENT_SUCCESS: "GET_ALL_PAYMENT_SUCCESS",
  GET_ALL_PAYMENT_ERROR: "GET_ALL_PAYMENT_ERROR",
  ADD_PAYMENT_START: "ADD_PAYMENT_START",
  ADD_PAYMENT_SUCCESS: "ADD_PAYMENT_SUCCESS",
  ADD_PAYMENT_ERROR: "ADD_PAYMENT_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_PAYMENT_LIST: "REFRESH_PAYMENT_LIST",
  SET_PAYMENT_BATCH_NUMBER: "SET_PAYMENT_BATCH_NUMBER",
  SELECT_PAYMENT_DETAILS: "SELECT_PAYMENT_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  UPDATE_PAYMENT_START: "UPDATE_PAYMENT_START",
  UPDATE_PAYMENT_SUCCESS: "UPDATE_PAYMENT_SUCCESS",
  UPDATE_PAYMENT_ERROR: "UPDATE_PAYMENT_ERROR",
  DELETE_PAYMENT_START: "DELETE_PAYMENT_START",
  DELETE_PAYMENT_SUCCESS: "DELETE_PAYMENT_SUCCESS",
  DELETE_PAYMENT_ERROR: "DELETE_PAYMENT_ERROR",
};

export const PaymentManagementActions = {
  getAllPAYMENTStart: (data) => ({
    type: PaymentManagementMap.GET_ALL_PAYMENT_START,
    payload: data,
  }),
  getAllPAYMENTSuccess: (data) => ({
    type: PaymentManagementMap.GET_ALL_PAYMENT_SUCCESS,
    payload: data,
  }),
  getAllPAYMENTError: (errors) => ({
    type: PaymentManagementMap.GET_ALL_PAYMENT_ERROR,
    payload: { errors },
  }),
  deletePAYMENTStart: (data) => ({
    type: PaymentManagementMap.DELETE_PAYMENT_START,
    payload: data,
  }),
  deletePAYMENTSuccess: (data) => ({
    type: PaymentManagementMap.DELETE_PAYMENT_SUCCESS,
    payload: data,
  }),
  deletePAYMENTError: (errors) => ({
    type: PaymentManagementMap.DELETE_PAYMENT_ERROR,
    payload: { errors },
  }),
  addPAYMENTStart: (data) => ({
    type: PaymentManagementMap.ADD_PAYMENT_START,
    payload: data,
  }),
  addPAYMENTSuccess: (data) => ({
    type: PaymentManagementMap.ADD_PAYMENT_SUCCESS,
    payload: data,
  }),
  addPAYMENTError: (errors) => ({
    type: PaymentManagementMap.ADD_PAYMENT_ERROR,
    payload: { errors },
  }),
  updatePAYMENTStart: (data) => ({
    type: PaymentManagementMap.UPDATE_PAYMENT_START,
    payload: data,
  }),
  updatePAYMENTSuccess: (data) => ({
    type: PaymentManagementMap.UPDATE_PAYMENT_SUCCESS,
    payload: data,
  }),
  updatePAYMENTError: (errors) => ({
    type: PaymentManagementMap.UPDATE_PAYMENT_ERROR,
    payload: { errors },
  }),
  getPAYMENTTemplateStart: (data) => ({
    type: PaymentManagementMap.GET_ALL_TEMPLATES_START,
    payload: data,
  }),
  getPAYMENTTemplateSuccess: (data) => ({
    type: PaymentManagementMap.GET_ALL_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getPAYMENTTemplateError: (errors) => ({
    type: PaymentManagementMap.GET_ALL_TEMPLATES_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: PaymentManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshPAYMENTList: () => ({ type: PaymentManagementMap.REFRESH_PAYMENT_LIST }),
  searchTextChange: (data) => ({
    type: PaymentManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedPAYMENT: (data) => ({
    type: PaymentManagementMap.SELECT_PAYMENT_DETAILS,
    payload: data,
  }),
  setPAYMENTBatchNumber: (data) => ({
    type: PaymentManagementMap.SET_PAYMENT_BATCH_NUMBER,
    payload: data,
  }),
  setSortEmail: (data) => ({
    type: PaymentManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),
};
