import { CardHeader } from "@material-ui/core";
import React, { useEffect } from "react";
import SVG from "react-inlinesvg";

import {
  Card,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls/Card";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/AssetsHelpers";
import { getUserDetailByIdAsync } from "../../redux";
import moment from "moment";

const UserBasicDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getUserDetailByIdAsync(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userDetail } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );
  const handleSelect = (key) => {};
  return (
    <Card>
      <CardHeader
        title={
          <div className="form-group m-0 d-flex align-items-center">
            <Link className="btn btn-link px-0" to="/users-management">
              <SVG
                className="h-50 align-self-center"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Arrow-left.svg"
                )}
              />
            </Link>
            <span className="pl-3 font_noto">User Details</span>
          </div>
        }
      />
      <CardHeaderToolbar>
        {" "}
        <div>
          <div></div>
        </div>
      </CardHeaderToolbar>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
        <div className="tblmrgn mt-0">
          <div className="basic_user_details_wrapper p-0">
            <div className="row">
              <div className="col-md-6 px-3">
                <div className="info_block mb-6">
                  <h3 class="h4">Personal Information</h3>
                  <table className="table_basic_user_details">
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">First Name </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.firstName || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Last Name </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.lastName || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Email </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.email || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Mobile </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.phoneNumber || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Country </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.countryData || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Joining Date </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.createdAt
                            ? moment(userDetail.createdAt).format(
                                "MMM DD, YYYY"
                              )
                            : "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Address </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 text-capitalize ml-0">
                          {userDetail?.address || "-"}
                        </span>
                      </td>
                    </tr>
                    {userDetail?.documentImage && (
                      <tr>
                        <td className="table-cell">
                          <label className="fn_s_16">
                            {/* KYC */}
                            KYC ID Proof
                          </label>
                        </td>
                        <td className="table-cell">
                          <span className="color_main ml-0">
                            {userDetail?.documentName || "-"}
                            <a href={userDetail?.documentImage} download>
                              <button
                                style={{
                                  fontSize: "11px",
                                  width: "auto",
                                  height: "auto",
                                }}
                                className="btn_new mt-1 ml-5"
                                type="button"
                              >
                                Download
                              </button>
                            </a>
                          </span>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
              <div className="col-md-6 px-3">
                <div className="info_block mb-6">
                  <h3 class="h4">Bank</h3>
                  <table className="table_basic_user_details">
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Bank Name</label>
                      </td>
                      <td>
                        <span className="color_main ">
                          {userDetail?.bankDetail?.name || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Bank Account Number </label>
                      </td>
                      <td>
                        <span className="color_main">
                          {userDetail?.bankDetail?.account || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="fn_s_16">IFSC Code </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850">
                          {userDetail?.bankDetail?.bankCode || "-"}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>

                <div className="info_block">
                  <h3 class="h4">KYC</h3>
                  <table className="table_basic_user_details">
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">IsKycVerified</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.isKycVerified === true ? " Yes" : " No"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="fn_s_16">IsDocumentVerified </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {userDetail?.isDocumentVerified === true
                            ? " Yes"
                            : " No"}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UserBasicDetails;
