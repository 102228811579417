import { WithdrawManagementMap } from "./WithdrawManagementAction";

const initialState = {
  isLoading: false,
  WithdrawList: {},
  refreshWithdrawList: true,
  selectedWithdraw: null,
  searchBy: "",
  dir: "",
  countryList: [],
  gateway: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WithdrawManagementMap.GET_ALL_WITHDRAW_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WithdrawManagementMap.GET_ALL_WITHDRAW_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        WithdrawList: action.payload,
        refreshWithdrawList: false,
      };
    }
    case WithdrawManagementMap.GET_ALL_WITHDRAW_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshWithdrawList: false,
      };
    }
    case WithdrawManagementMap.ADD_WITHDRAW_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WithdrawManagementMap.ADD_WITHDRAW_SUCCESS: {
      return {
        ...state,
        refreshWithdrawList: true,
      };
    }
    case WithdrawManagementMap.ADD_WITHDRAW_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshWithdrawList: false,
      };
    }
    case WithdrawManagementMap.UPDATE_WITHDRAW_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WithdrawManagementMap.UPDATE_WITHDRAW_SUCCESS: {
      return {
        ...state,
        refreshWithdrawList: true,
      };
    }
    case WithdrawManagementMap.UPDATE_WITHDRAW_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshWithdrawList: false,
      };
    }
    case WithdrawManagementMap.GET_ALL_COUNTRY_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WithdrawManagementMap.GET_ALL_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        countryList: action.payload,
        refreshWithdrawList: true,
      };
    }
    case WithdrawManagementMap.GET_ALL_COUNTRY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        countryList: [],
        refreshWithdrawList: false,
      };
    }
    case WithdrawManagementMap.GET_ALL_GATEWAY_LIST_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WithdrawManagementMap.GET_ALL_GATEWAY_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        gateway: action.payload,
        refreshWithdrawList: true,
      };
    }
    case WithdrawManagementMap.GET_ALL_GATEWAY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        gateway: [],
        refreshWithdrawList: false,
      };
    }
    case WithdrawManagementMap.SELECT_WITHDRAW_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedWithdraw: action.payload,
      };
    }
    case WithdrawManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case WithdrawManagementMap.SET_WITHDRAW_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshWithdrawList: true,
      };
    }
    case WithdrawManagementMap.REFRESH_WITHDRAW_LIST: {
      return {
        ...state,
        skip: 0,
        refreshWithdrawList: true,
      };
    }
    case WithdrawManagementMap.DELETE_WITHDRAW_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WithdrawManagementMap.DELETE_WITHDRAW_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshWithdrawList: true,
      };
    }
    case WithdrawManagementMap.DELETE_WITHDRAW_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshWithdrawList: false,
      };
    }
    case WithdrawManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }
    default:
      return { ...state };
  }
};
