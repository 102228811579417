import { useFormik } from "formik";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateUserProfileAsync } from "../redux/profileApi";

const ProfileBasicDetails = (props) => {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const dispatch = useDispatch();
  const initialValues = {
    email: user.email || "",
    name: user.name || "",
    appToken: user.appToken || "",
  };

  const ProfileSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Enter valid email")
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("Email is required"),
    });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ProfileSchema,
    onSubmit: (values, { setErrors, resetForm }) => {
      let data = null;
      data = new FormData();
      data.append("email", values.email);
      dispatch(updateUserProfileAsync(data));
      resetForm();
    },
  });

  useEffect(() => {
    if (props && props.selectedTab !== "basic-details") {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.selectedTab]);

  return (
    <>
      <div className="tblmrgn mt-0 lwpddng cstm_pddng">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-6-as">
            <form
              className="form def_form frmwtpddng"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <div className="re_grpPhone position-relative">
                      <input
                        disabled
                        placeholder="username@company.com"
                        type="email"
                        className={`form-control wth_chng`}
                        name="email"
                        {...formik.getFieldProps("email")}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileBasicDetails;
