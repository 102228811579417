import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { DepositManagementActions } from "./DepositManagementAction";

export const getAllDepositAsync = (
  searchBy,
  searchText,
  dir,
  depositFilter
) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().DepositManagement;
      dispatch(DepositManagementActions.getAllDepositStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/pool/deposit/list?skip=${skip *
          10}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...depositFilter,
        },
      });
      if (data.code === 200) {
        return dispatch(
          DepositManagementActions.getAllDepositSuccess(data.data)
        );
      }
      dispatch(DepositManagementActions.getAllDepositError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(DepositManagementActions.getAllDepositError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addDepositAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(DepositManagementActions.addDepositStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/Deposit/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(DepositManagementActions.addDepositSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(DepositManagementActions.addDepositError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(DepositManagementActions.addDepositError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateDepositAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      let { selectedDeposit } = getState().DepositManagement;
      dispatch(DepositManagementActions.updateDepositStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}/api/Deposit/updateDetails/${selectedDeposit._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(DepositManagementActions.updateDepositSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(DepositManagementActions.updateDepositError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(DepositManagementActions.updateDepositError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteDepositAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(DepositManagementActions.deleteDepositStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/Deposit/delete/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(DepositManagementActions.deleteDepositSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(DepositManagementActions.deleteDepositError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(DepositManagementActions.deleteDepositError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
