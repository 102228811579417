import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { COUNTRYManagementActions } from "./CountryManagementAction";

export const getAllCOUNTRYAsync = (searchBy, searchText, dir) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().cmsManagement;
      dispatch(COUNTRYManagementActions.getAllCOUNTRYStart());

      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}/api/countries/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          COUNTRYManagementActions.getAllCOUNTRYSuccess(data.data)
        );
      }
      dispatch(COUNTRYManagementActions.getAllCOUNTRYError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(COUNTRYManagementActions.getAllCOUNTRYError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addCOUNTRYAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(COUNTRYManagementActions.addCOUNTRYStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/cms/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(COUNTRYManagementActions.addCOUNTRYSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(COUNTRYManagementActions.addCOUNTRYError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(COUNTRYManagementActions.addCOUNTRYError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateCOUNTRYAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      let { selectedCOUNTRY } = getState().cmsManagement;
      dispatch(COUNTRYManagementActions.updateCOUNTRYStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}/api/cms/updateDetails/${selectedCOUNTRY._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(COUNTRYManagementActions.updateCOUNTRYSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(COUNTRYManagementActions.updateCOUNTRYError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(COUNTRYManagementActions.updateCOUNTRYError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteCOUNTRYAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(COUNTRYManagementActions.deleteCOUNTRYStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/cms/delete/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(COUNTRYManagementActions.deleteCOUNTRYSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(COUNTRYManagementActions.deleteCOUNTRYError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(COUNTRYManagementActions.deleteCOUNTRYError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
