import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { CONTACTManagementActions } from "../../redux";

const ContactUsTable = () => {
  const { isLoading, ContactList, skip, limit, dir } = useSelector(
    (state) => state.contactUs,
    shallowEqual
  );
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(CONTACTManagementActions.setCONTACTBatchNumber(count));
  };
  const handleSort = (column, dir) => {
    dispatch(CONTACTManagementActions.setSortCountry({ column, dir }));
  };

  useEffect(
    () => handleSort("", ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "title",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Title{" "}
                    {dir !== "" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "title",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Description{" "}
                    {dir !== "" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th width="100px">Email</th>
                </tr>
              </thead>
              <tbody>
                {ContactList?.records && ContactList?.records.length ? (
                  ContactList?.records.map((ContactLists, index) => (
                    <tr key={index}>
                      <td>
                        <span className="">{skip * limit + (index + 1)}</span>
                      </td>
                      <td>
                        <span className="">{ContactLists.title}</span>
                      </td>
                      <td>
                        <span
                          title={ContactLists.description}
                          placeholder="Input tooltip"
                          data-styletip-class="op-default"
                        >
                          {" "}
                          {ContactLists.description
                            .substring(0, 20)
                            .concat("...")}
                        </span>
                        {/*  <Tooltip
                          data-mdb-custom-class="text-sm"
                          title={ContactLists.description}
                          placement="top"
                        >
                          <span>
                            {ContactLists.description
                              .substring(0, 20)
                              .concat("...")}
                          </span>
                        </Tooltip> */}
                      </td>
                      <td>{ContactLists.email}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isLoading && (
            <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
              <div className="col-md-8 mb-5 aspgntn">
                <BasicPagination
                  totalRecords={ContactList?.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_14 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {ContactList?.recordsTotal} Records
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want you to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <button
                type="button"
                className="btn_new btn-sm mr-3"
                onClick={deleteClose}
              >
                Close
              </button>
              <button type="button" className="btn_new btn-sm">
                Yes, Delete
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContactUsTable;
