import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { DISPUTEManagementActions } from "./DisputeAction";

export const getAllDISPUTEAsync = (searchBy, searchText, dir) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().dispute;
      dispatch(DISPUTEManagementActions.getAllDISPUTEStart());

      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}/api/order/getAllDispute?skip=${skip}&limit=${limit}&column=${searchBy}&search=${searchText}&dir=${dir}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          DISPUTEManagementActions.getAllDISPUTESuccess(data.data)
        );
      }
      dispatch(DISPUTEManagementActions.getAllDISPUTEError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(DISPUTEManagementActions.getAllDISPUTEError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getDisputeDetailByIdAsync = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(DISPUTEManagementActions.getDesputeDetailsStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/order/disputeById/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return await dispatch(
          DISPUTEManagementActions.getDesputeDetailsSuccess(data.data)
        );
      }
      dispatch(DISPUTEManagementActions.getDesputeDetailsError());
    } catch (error) {
      dispatch(DISPUTEManagementActions.getDesputeDetailsError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getLogDetailByIdAsync = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(DISPUTEManagementActions.getLogDetailsStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/order/logByOrderId/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return await dispatch(
          DISPUTEManagementActions.getLogDetailsSuccess(data.data)
        );
      }
      dispatch(DISPUTEManagementActions.getLogDetailsError());
    } catch (error) {
      dispatch(DISPUTEManagementActions.getLogDetailsError());
    }
  };
};
export const updateDisputeAsync = (obj) => {
  return async (dispatch, getState) => {
    try {
      dispatch(DISPUTEManagementActions.updateDISPUTEStart());
      const { data } = await axios({
        method: "Put",
        url: `${process.env.REACT_APP_HOST}/api/order/updateDispute`,
        headers: {
          "Content-Type": "application/json",
        },
        data: obj,
      });
      if (data.code === 200) {
        dispatch(DISPUTEManagementActions.updateDISPUTESuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(DISPUTEManagementActions.updateDISPUTEError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(DISPUTEManagementActions.updateDISPUTEError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const setLoader = (data) => {
  return async (dispatch, getState) => {
    dispatch(DISPUTEManagementActions.setLoader(data));
  };
};
