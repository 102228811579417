export const OrderManagementMap = {
  GET_ALL_Order_START: "GET_ALL_Order_START",
  GET_ALL_Order_SUCCESS: "GET_ALL_Order_SUCCESS",
  GET_ALL_Order_ERROR: "GET_ALL_Order_ERROR",
  ADD_Order_START: "ADD_Order_START",
  ADD_Order_SUCCESS: "ADD_Order_SUCCESS",
  ADD_Order_ERROR: "ADD_Order_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_Order_LIST: "REFRESH_Order_LIST",
  SET_Order_BATCH_NUMBER: "SET_Order_BATCH_NUMBER",
  SELECT_Order_DETAILS: "SELECT_Order_DETAILS",
  GET_ALL_COUNTRY_LIST_START: "GET_ALL_COUNTRY_LIST_START",
  GET_ALL_COUNTRY_LIST_SUCCESS: "GET_ALL_COUNTRY_LIST_SUCCESS",
  GET_ALL_COUNTRY_LIST_ERROR: "GET_ALL_COUNTRY_LIST_ERROR",
  GET_ALL_GATEWAY_LIST_START: "GET_ALL_GATEWAY_LIST_START",
  GET_ALL_GATEWAY_LIST_SUCCESS: "GET_ALL_GATEWAY_LIST_SUCCESS",
  GET_ALL_GATEWAY_LIST_ERROR: "GET_ALL_GATEWAY_LIST_ERROR",
  UPDATE_Order_START: "UPDATE_Order_START",
  UPDATE_Order_SUCCESS: "UPDATE_Order_SUCCESS",
  UPDATE_Order_ERROR: "UPDATE_Order_ERROR",
  DELETE_Order_START: "DELETE_Order_START",
  DELETE_Order_SUCCESS: "DELETE_Order_SUCCESS",
  DELETE_Order_ERROR: "DELETE_Order_ERROR",

  GET_ORDER_DETAILS_START: "GET_ORDER_DETAILS_START",
  GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
  GET_ORDER_DETAILS_ERROR: "GET_ORDER_DETAILS_ERROR",
};

export const OrderManagementActions = {
  getAllOrderStart: (data) => ({
    type: OrderManagementMap.GET_ALL_Order_START,
    payload: data,
  }),
  getAllOrderSuccess: (data) => ({
    type: OrderManagementMap.GET_ALL_Order_SUCCESS,
    payload: data,
  }),
  getAllOrderError: (errors) => ({
    type: OrderManagementMap.GET_ALL_Order_ERROR,
    payload: { errors },
  }),
  deleteOrderStart: (data) => ({
    type: OrderManagementMap.DELETE_Order_START,
    payload: data,
  }),
  deleteOrderSuccess: (data) => ({
    type: OrderManagementMap.DELETE_Order_SUCCESS,
    payload: data,
  }),
  deleteOrderError: (errors) => ({
    type: OrderManagementMap.DELETE_Order_ERROR,
    payload: { errors },
  }),
  addOrderStart: (data) => ({
    type: OrderManagementMap.ADD_Order_START,
    payload: data,
  }),
  addOrderSuccess: (data) => ({
    type: OrderManagementMap.ADD_Order_SUCCESS,
    payload: data,
  }),
  addOrderError: (errors) => ({
    type: OrderManagementMap.ADD_Order_ERROR,
    payload: { errors },
  }),
  updateOrderStart: (data) => ({
    type: OrderManagementMap.UPDATE_Order_START,
    payload: data,
  }),
  updateOrderSuccess: (data) => ({
    type: OrderManagementMap.UPDATE_Order_SUCCESS,
    payload: data,
  }),
  updateOrderError: (errors) => ({
    type: OrderManagementMap.UPDATE_Order_ERROR,
    payload: { errors },
  }),
  getOrderCountryListStart: (data) => ({
    type: OrderManagementMap.GET_ALL_COUNTRY_LIST_START,
    payload: data,
  }),
  getOrderCountryListSuccess: (data) => ({
    type: OrderManagementMap.GET_ALL_COUNTRY_LIST_SUCCESS,
    payload: data,
  }),
  getOrderCountryListError: (errors) => ({
    type: OrderManagementMap.GET_ALL_COUNTRY_LIST_ERROR,
    payload: { errors },
  }),
  getOrderGatewayListStart: (data) => ({
    type: OrderManagementMap.GET_ALL_GATEWAY_LIST_START,
    payload: data,
  }),
  getOrderGatewayListSuccess: (data) => ({
    type: OrderManagementMap.GET_ALL_GATEWAY_LIST_SUCCESS,
    payload: data,
  }),
  getOrderGatewayListError: (errors) => ({
    type: OrderManagementMap.GET_ALL_GATEWAY_LIST_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: OrderManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshOrderList: () => ({ type: OrderManagementMap.REFRESH_Order_LIST }),
  searchTextChange: (data) => ({
    type: OrderManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedOrder: (data) => ({
    type: OrderManagementMap.SELECT_Order_DETAILS,
    payload: data,
  }),
  setOrderBatchNumber: (data) => ({
    type: OrderManagementMap.SET_Order_BATCH_NUMBER,
    payload: data,
  }),
  setSortEmail: (data) => ({
    type: OrderManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),

  getOrderDetailStart: (data) => ({
    type: OrderManagementMap.GET_ORDER_DETAILS_START,
    payload: data,
  }),
  getOrderDetailSuccess: (data) => ({
    type: OrderManagementMap.GET_ORDER_DETAILS_SUCCESS,
    payload: data,
  }),
  getOrderDetailError: (errors) => ({
    type: OrderManagementMap.GET_ORDER_DETAILS_ERROR,
    payload: { errors },
  }),
};
