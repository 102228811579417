import { ContactUsMap } from "./ContactUsAction";

const initialState = {
  isLoading: false,
  ContactList: {},
  refreshContactList: true,
  selectedContact: null,
  searchBy: "",
  dir: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ContactUsMap.GET_ALL_CONTACT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ContactUsMap.GET_ALL_CONTACT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ContactList: action.payload,
        refreshContactList: false,
      };
    }
    case ContactUsMap.GET_ALL_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }
    case ContactUsMap.ADD_CONTACT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ContactUsMap.ADD_CONTACT_SUCCESS: {
      return {
        ...state,
        refreshContactList: true,
      };
    }
    case ContactUsMap.ADD_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }
    case ContactUsMap.UPDATE_CONTACT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ContactUsMap.UPDATE_CONTACT_SUCCESS: {
      return {
        ...state,
        refreshContactList: true,
      };
    }
    case ContactUsMap.UPDATE_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }
    case ContactUsMap.GET_ALL_TEMPLATES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ContactUsMap.GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templates: action.payload,
        refreshContactList: true,
      };
    }
    case ContactUsMap.GET_ALL_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        templates: [],
        refreshContactList: false,
      };
    }
    case ContactUsMap.SELECT_CONTACT_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedContact: action.payload,
      };
    }
    case ContactUsMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case ContactUsMap.SET_CONTACT_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshContactList: true,
      };
    }
    case ContactUsMap.REFRESH_CONTACT_LIST: {
      return {
        ...state,
        skip: 0,
        refreshContactList: true,
      };
    }
    case ContactUsMap.DELETE_CONTACT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ContactUsMap.DELETE_CONTACT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: true,
      };
    }
    case ContactUsMap.DELETE_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshContactList: false,
      };
    }
    case ContactUsMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }
    default:
      return { ...state };
  }
};
