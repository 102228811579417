import { CardHeader } from "@material-ui/core";
import React, { useEffect } from "react";
import SVG from "react-inlinesvg";

import {
  Card,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls/Card";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Tab, Table, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/AssetsHelpers";
import { getUserTradingDetailAsync } from "../../redux";
import moment from "moment";

const UserTradingDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getUserTradingDetailAsync(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userTradingDetails } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );
  const handleSelect = (key) => {};
  return (
    <Card>
      <CardHeader
        title={
          <div className="form-group m-0 d-flex align-items-center">
            <Link className="btn btn-link px-0" to="/users-management">
              <SVG
                className="h-50 align-self-center"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Arrow-left.svg"
                )}
              />
            </Link>
            <span className="pl-3 font_noto">User Trading Details</span>
          </div>
        }
      />
      <CardHeaderToolbar>
        {" "}
        <div>
          <div></div>
        </div>
      </CardHeaderToolbar>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
        <div className="tblmrgn mt-0">
          <div className="mt-5">
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>TokenName</th>
                  <th>Leverage</th>
                  <th>Platform</th>
                  <th>AccountNumber</th>
                </tr>
              </thead>
              <tbody>
                {userTradingDetails?.length ? (
                  userTradingDetails.map((logData, index) => (
                    <tr key={index}>
                      <td style={{ cursor: "pointer" }}>
                        {moment(logData.createdAt).format("MMM DD, YYYY")}
                      </td>
                      <td className="m_title">{logData?.tokenName || "-"}</td>
                      <td>{logData?.leverage || "-"}</td>
                      <td>{logData?.platform || "-"}</td>
                      <td>{logData?.accountNumber || "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UserTradingDetails;
