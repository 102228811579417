export const DepositManagementMap = {
  GET_ALL_DEPOSIT_START: "GET_ALL_DEPOSIT_START",
  GET_ALL_DEPOSIT_SUCCESS: "GET_ALL_DEPOSIT_SUCCESS",
  GET_ALL_DEPOSIT_ERROR: "GET_ALL_DEPOSIT_ERROR",
  ADD_DEPOSIT_START: "ADD_DEPOSIT_START",
  ADD_DEPOSIT_SUCCESS: "ADD_DEPOSIT_SUCCESS",
  ADD_DEPOSIT_ERROR: "ADD_DEPOSIT_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_DEPOSIT_LIST: "REFRESH_DEPOSIT_LIST",
  SET_DEPOSIT_BATCH_NUMBER: "SET_DEPOSIT_BATCH_NUMBER",
  SELECT_DEPOSIT_DETAILS: "SELECT_DEPOSIT_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  UPDATE_DEPOSIT_START: "UPDATE_DEPOSIT_START",
  UPDATE_DEPOSIT_SUCCESS: "UPDATE_DEPOSIT_SUCCESS",
  UPDATE_DEPOSIT_ERROR: "UPDATE_DEPOSIT_ERROR",
  DELETE_DEPOSIT_START: "DELETE_DEPOSIT_START",
  DELETE_DEPOSIT_SUCCESS: "DELETE_DEPOSIT_SUCCESS",
  DELETE_DEPOSIT_ERROR: "DELETE_DEPOSIT_ERROR",
};

export const DepositManagementActions = {
  getAllDepositStart: (data) => ({
    type: DepositManagementMap.GET_ALL_DEPOSIT_START,
    payload: data,
  }),
  getAllDepositSuccess: (data) => ({
    type: DepositManagementMap.GET_ALL_DEPOSIT_SUCCESS,
    payload: data,
  }),
  getAllDepositError: (errors) => ({
    type: DepositManagementMap.GET_ALL_DEPOSIT_ERROR,
    payload: { errors },
  }),
  deleteDepositStart: (data) => ({
    type: DepositManagementMap.DELETE_DEPOSIT_START,
    payload: data,
  }),
  deleteDepositSuccess: (data) => ({
    type: DepositManagementMap.DELETE_DEPOSIT_SUCCESS,
    payload: data,
  }),
  deleteDepositError: (errors) => ({
    type: DepositManagementMap.DELETE_DEPOSIT_ERROR,
    payload: { errors },
  }),
  addDepositStart: (data) => ({
    type: DepositManagementMap.ADD_DEPOSIT_START,
    payload: data,
  }),
  addDepositSuccess: (data) => ({
    type: DepositManagementMap.ADD_DEPOSIT_SUCCESS,
    payload: data,
  }),
  addDepositError: (errors) => ({
    type: DepositManagementMap.ADD_DEPOSIT_ERROR,
    payload: { errors },
  }),
  updateDepositStart: (data) => ({
    type: DepositManagementMap.UPDATE_DEPOSIT_START,
    payload: data,
  }),
  updateDepositSuccess: (data) => ({
    type: DepositManagementMap.UPDATE_DEPOSIT_SUCCESS,
    payload: data,
  }),
  updateDepositError: (errors) => ({
    type: DepositManagementMap.UPDATE_DEPOSIT_ERROR,
    payload: { errors },
  }),
  getDepositTemplateStart: (data) => ({
    type: DepositManagementMap.GET_ALL_TEMPLATES_START,
    payload: data,
  }),
  getDepositTemplateSuccess: (data) => ({
    type: DepositManagementMap.GET_ALL_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getDepositTemplateError: (errors) => ({
    type: DepositManagementMap.GET_ALL_TEMPLATES_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: DepositManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshDepositList: () => ({ type: DepositManagementMap.REFRESH_DEPOSIT_LIST }),
  searchTextChange: (data) => ({
    type: DepositManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedDeposit: (data) => ({
    type: DepositManagementMap.SELECT_DEPOSIT_DETAILS,
    payload: data,
  }),
  setDepositBatchNumber: (data) => ({
    type: DepositManagementMap.SET_DEPOSIT_BATCH_NUMBER,
    payload: data,
  }),
  setSortEmail: (data) => ({
    type: DepositManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),
};
