import { PaymentManagementMap } from "./PaymentManagementAction";

const initialState = {
  isLoading: false,
  PAYMENTList: {},
  refreshPAYMENTList: true,
  selectedPAYMENT: null,
  searchBy: "",
  dir: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentManagementMap.GET_ALL_PAYMENT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentManagementMap.GET_ALL_PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        PAYMENTList: action.payload,
        refreshPAYMENTList: false,
      };
    }
    case PaymentManagementMap.GET_ALL_PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshPAYMENTList: false,
      };
    }
    case PaymentManagementMap.ADD_PAYMENT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentManagementMap.ADD_PAYMENT_SUCCESS: {
      return {
        ...state,
        refreshPAYMENTList: true,
      };
    }
    case PaymentManagementMap.ADD_PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshPAYMENTList: false,
      };
    }
    case PaymentManagementMap.UPDATE_PAYMENT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentManagementMap.UPDATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        refreshPAYMENTList: true,
      };
    }
    case PaymentManagementMap.UPDATE_PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshPAYMENTList: false,
      };
    }
    case PaymentManagementMap.GET_ALL_TEMPLATES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentManagementMap.GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templates: action.payload,
        refreshPAYMENTList: true,
      };
    }
    case PaymentManagementMap.GET_ALL_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        templates: [],
        refreshPAYMENTList: false,
      };
    }
    case PaymentManagementMap.SELECT_PAYMENT_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedPAYMENT: action.payload,
      };
    }
    case PaymentManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case PaymentManagementMap.SET_PAYMENT_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshPAYMENTList: true,
      };
    }
    case PaymentManagementMap.REFRESH_PAYMENT_LIST: {
      return {
        ...state,
        skip: 0,
        refreshPAYMENTList: true,
      };
    }
    case PaymentManagementMap.DELETE_PAYMENT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentManagementMap.DELETE_PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshPAYMENTList: true,
      };
    }
    case PaymentManagementMap.DELETE_PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshPAYMENTList: false,
      };
    }
    case PaymentManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }
    default:
      return { ...state };
  }
};
