import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  addPaymentMasterAsync,
  getAllPaymentMasterGatewayAsync,
  getAllPaymentMasterTemplatesAsync,
  updatePaymentMasterAsync,
} from "../redux";

const AddPaymentMaster = () => {
  const templateOptions = [];
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const entities = [];

  useEffect(() => {
    dispatch(getAllPaymentMasterGatewayAsync());
    dispatch(getAllPaymentMasterTemplatesAsync());
  }, [dispatch]);

  const { selectedpaymentmasterlist, templates, gateway } = useSelector(
    (state) => state.paymentMasterManagement,
    shallowEqual
  );
  if (!selectedpaymentmasterlist && params?.id !== undefined) {
    return <Redirect to="/paymentMaster" />;
  }

  if (selectedpaymentmasterlist && params?.id && templates) {
    templates.forEach((template) => {
      if (selectedpaymentmasterlist.countryId === template._id) {
        entities.push({
          value: selectedpaymentmasterlist._id,
          label: selectedpaymentmasterlist.countryName,
        });
      }
    });
  }

  const initialValues = {
    countryId: "",
    countryName: "",
    paymentGateway: [],
    templates: [],
  };
  if (templates && templates.length > 0) {
    initialValues.templates = templates;
    templates.forEach((country) => {
      templateOptions.push({
        value: country._id,
        label: country.name,
      });
    });
  }
  const redirectBack = () => {
    history.push("/paymentMaster");
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          selectedpaymentmasterlist && params?.id !== undefined
            ? selectedpaymentmasterlist
            : initialValues
        }
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (selectedpaymentmasterlist && params?.id !== undefined) {
            let obj = {
              countryId: values.countryId,
              paymentGateway: values.paymentGateway,
            };
            dispatch(
              updatePaymentMasterAsync(
                obj,
                setSubmitting,
                resetForm,
                redirectBack
              )
            );
          } else {
            let obj = {
              countryId: values.countryId,
              paymentGateway: values.paymentGateway,
            };
            dispatch(
              addPaymentMasterAsync(obj, setSubmitting, resetForm, redirectBack)
            );
          }
        }}
      >
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Card>
            <CardHeader
              title={
                <div className="form-group m-0 d-flex align-items-center">
                  <Link className="btn btn-link px-0" to="/paymentMaster">
                    <SVG
                      className="h-50 align-self-center"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-left.svg"
                      )}
                    />
                    
                  </Link>
                  <span className="pl-2">
                    {selectedpaymentmasterlist
                      ? "Edit Payment Gateway"
                      : "Add Payment Gateway"}
                  </span>
                </div>
              }
            ></CardHeader>
            <CardBody>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Form>
                    <div className="form-group">
                      <label className="pho_14_500">Payment Country</label>
                      <Select
                        value={{
                          value: values.countryId,
                          label: values.countryName,
                        }}
                        isDisabled={
                          selectedpaymentmasterlist && params?.id !== undefined
                        }
                        placeholder="Select Payment Gateway"
                        className={`form-control border-0 p-0 `}
                        classNamePrefix="phoSelect"
                        options={templateOptions}
                        onChange={(e) => {
                          const entities = [{ values: "", label: "" }];
                          entities.push({ label: e._id, value: e.countryName });
                          setFieldValue("countryId", e.value);
                          setFieldValue("countryName", e.label);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#DCF4E4",
                            primary75: "#DCF4E4",
                            primary50: "#DCF4E4",
                            primary: "#50C878",
                          },
                        })}
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Payment Gateway</label>
                      {gateway && gateway.length ? (
                        gateway.map((gatewaySelection, index) => (
                          <div key={gatewaySelection?._id}>
                            <input
                              key={gatewaySelection?._id}
                              type="checkbox"
                              name="paymentGateway"
                              onChange={handleChange}
                              id={gatewaySelection?._id}
                              value={gatewaySelection?._id}
                              defaultChecked={
                                (selectedpaymentmasterlist?.paymentGateway &&
                                  selectedpaymentmasterlist?.paymentGateway
                                    .length) >= 0
                                  ? selectedpaymentmasterlist?.paymentGateway.includes(
                                      gatewaySelection?._id
                                    )
                                  : false
                              }
                              style={{ margin: "10px 10px 10px 10px" }}
                            />
                            <span>{gatewaySelection.name}</span>
                          </div>
                        ))
                      ) : (
                        <div>No Record Found</div>
                      )}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="mr-5 mb-1 mt-2">
                        <Link
                          className="btn btn-outline-blue"
                          to="/paymentMaster"
                        >
                          Close
                        </Link>
                      </div>
                      <div className="mb-1 mt-2">
                        <Button
                          variant="blue"
                          className="px-5 defpddng spinnerBtn"
                          size="lg"
                          type="submit"
                        >
                          {selectedpaymentmasterlist && params?.id !== undefined
                            ? "Update"
                            : "Submit"}
                          {isSubmitting && (
                            <div className="ml-3 basic-verification-loader text-center"></div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Formik>
    </>
  );
};
export default AddPaymentMaster;
