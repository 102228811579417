import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateGeneralSettingAsync } from "../../redux/generalSettingApi";
import { CircularProgress } from "@material-ui/core";

const AddGeneralSetting = ({ isLoading }) => {
  const { selectedGeneralSetting } = useSelector(
    (state) => state.generalSetting,
    shallowEqual
  );

  const dispatch = useDispatch();

  const AddGeneralSettingFormSchema = () =>
    Yup.object().shape({
      accountTradingLimit: Yup.number().required(
        "Account trading limit is required."
      ),
    });

  const formik = useFormik({
    initialValues: {
      accountTradingLimit: selectedGeneralSetting.accountTradingLimit || "",
    },
    enableReinitialize: true,
    validationSchema: AddGeneralSettingFormSchema,
    onSubmit: (values) => {
      if (values) {
        return dispatch(
          updateGeneralSettingAsync(selectedGeneralSetting._id, values)
        );
      }
    },
  });

  return (
    <>
      <div className="tblmrgn mt-10 lwpddng cstm_pddng">
        <div className="row justify-content-center">
          <div className="col-xl-8 ">
            <form
              className="form def_form frmwtpddng"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label"> Trading Account Limit</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control`}
                      {...formik.getFieldProps("accountTradingLimit")}
                      name="accountTradingLimit"
                    />
                    {formik.touched.accountTradingLimit &&
                    formik.errors.accountTradingLimit ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.accountTradingLimit}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    type="submit"
                    className="btn btn-blue spinnerBtn"
                    disabled={isLoading}
                  >
                    <span>Save</span>
                    {isLoading && (
                      <div className="ml-3 basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    )}
                  </button>
                  <br />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddGeneralSetting;
