import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  getAllOrderAsync,
  getAllOrderCountryListsAsync,
  getAllOrderGatewayListsAsync,
  OrderManagementActions,
} from "../redux";
import OrderManagementTable from "./orderManagementDetail/orderManagementTable";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";

export function OrderManagement() {
  const countryListOptions = [];
  const currency = [];
  const paymentMethod = [];
  const dispatch = useDispatch();
  const searchOrderRef = useRef();

  const {
    refreshOrderList,
    searchBy,
    gateway,
    countryList,
    searchText,
    dir,
  } = useSelector((state) => state.OrderManagement, shallowEqual);

  const [orderFilter, setorderFilter] = useState({
    countryId: null,
    paymentId: null,
    currency: null,
    sdate: null,
    edate: null,
  });

  const initialValues = {
    countryList: [],
  };

  if (countryList && countryList.length > 0) {
    initialValues.countryList = countryList;
    countryList.forEach((country) => {
      countryListOptions.push({
        value: country._id,
        label: country.name,
      });
    });
  }

  if (countryList && countryList.length > 0) {
    countryList.forEach((curr) => {
      currency.push({
        label: curr.currency,
        value: curr._id,
      });
    });
  }

  if (gateway && gateway.length > 0) {
    gateway.forEach((gateway) => {
      paymentMethod.push({
        label: gateway.name,
        value: gateway._id,
      });
    });
  }

  useEffect(() => {
    dispatch(OrderManagementActions.setSelectedOrder(null));
    if (searchOrderRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(OrderManagementActions.refreshOrderList());
        }
      };
      let input = searchOrderRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(OrderManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (refreshOrderList) {
      dispatch(getAllOrderAsync(searchBy, searchText, dir, orderFilter));
    }
    // dispatch(getAllOrderAsync(searchBy, searchText, dir, orderFilter));
  }, [dispatch, refreshOrderList, searchBy, searchText, dir, orderFilter]);

  useEffect(() => {
    dispatch(getAllOrderCountryListsAsync());
    dispatch(getAllOrderGatewayListsAsync());
  }, [dispatch]);

  const onResetClick = (e) => {
    setorderFilter({
      ...orderFilter,
      countryId: null,
      paymentId: null,
      currency: null,
      sdate: null,
      edate: null,
    });
    setStateDate([]);
    dispatch(OrderManagementActions.setOrderBatchNumber(0));
    dispatch(OrderManagementActions.searchTextChange(""));
  };

  const onDateChange = (e) => {
    if (e && e[0]) {
      let date = {
        startDate: e[0]
          .toDate()
          .toISOString()
          .slice(0, 10),
        endDate:
          e.length && e.length === 2
            ? e[1]
                .toDate()
                .toISOString()
                .slice(0, 10)
            : e[0]
                .toDate()
                .toISOString()
                .slice(0, 10),
      };
      dispatch(OrderManagementActions.setOrderBatchNumber(0));
      setorderFilter({ sdate: date.startDate, edate: date.endDate });
    }
  };

  const [stateDate, setStateDate] = useState([]);

  return (
    <>
      <Card>
        <CardHeader title="Order Management" />
        <CardHeaderToolbar className="filter_block">
          <Formik
            initialValues={{
              amount: "",
              country: "",
              currency: "",
              payment: "",
              startDate: "",
              endDate: "",
            }}
          >
            {(formProps) => (
              <Form>
                <div
                  className="form-group mb-0 p-5"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: "15px",
                  }}
                >
                  <div className="" style={{ width: "20rem" }}>
                    <label htmlFor="country">Country</label>
                    <Select
                      name="country"
                      placeholder="Select a country"
                      className={`form-control border-0 p-0 iv_select`}
                      options={countryListOptions}
                      onChange={(e) => {
                        formProps.setFieldValue("country", e.value);
                        // refreshOrderList == true
                        dispatch(OrderManagementActions.setOrderBatchNumber(0));

                        setorderFilter({
                          ...orderFilter,
                          countryId: e.value,
                        });
                      }}
                      value={[
                        {
                          value: formProps.values.country
                            ? formProps.values.country
                            : null,
                          label: formProps.values.country
                            ? countryListOptions.find(
                                (element) =>
                                  element.value === formProps.values.country
                              )?.label
                            : "Select a country",
                        },
                      ]}
                      classNamePrefix="lv_dropdown"
                    />
                  </div>
                  <div className="" style={{ width: "20rem" }}>
                    <label>Currency</label>
                    <Select
                      as="select"
                      placeholder="Select a currency"
                      name="currency"
                      className={`form-control border-0 p-0 iv_select bg-none`}
                      options={currency}
                      onChange={(e) => {
                        const currencyID = e.value;
                        const resCurrency = currency.filter((curr) => {
                          if (curr.value === currencyID) {
                            return curr.label;
                          }
                          return undefined;
                        });
                        formProps.setFieldValue("currency", e.value);
                        dispatch(OrderManagementActions.setOrderBatchNumber(0));

                        setorderFilter({
                          ...orderFilter,
                          currency: resCurrency[0].label,
                        });
                      }}
                      value={[
                        {
                          value: formProps.values.currency
                            ? formProps.values.currency
                            : null,
                          label: formProps.values.currency
                            ? currency.find(
                                (element) =>
                                  element.value === formProps.values.currency
                              )?.label
                            : "Select a currency",
                        },
                      ]}
                      classNamePrefix="lv_dropdown"
                    />
                  </div>
                  <div className="" style={{ width: "20rem" }}>
                    <label>Payment Method</label>
                    <Select
                      as="select"
                      name="payment"
                      placeholder="Select a payment method"
                      className={`form-control border-0 p-0 iv_select bg-none`}
                      options={paymentMethod}
                      onChange={(e) => {
                        formProps.setFieldValue("payment", e.value);
                        dispatch(OrderManagementActions.setOrderBatchNumber(0));

                        setorderFilter({
                          ...orderFilter,
                          paymentId: e.value,
                        });
                      }}
                      defaultValue=""
                      value={[
                        {
                          value: formProps.values.payment
                            ? formProps.values.payment
                            : "",
                          label: formProps.values.payment
                            ? paymentMethod.find(
                                (element) =>
                                  element.value === formProps.values.payment
                              )?.label
                            : "Select a payment method",
                        },
                      ]}
                      classNamePrefix="lv_dropdown"
                    />
                    {/*  <option value="all" defaultValue hidden>
                      Select a payment method
                    </option>
                    {paymentMethod.map((region) => {
                      return (
                        <option value={region.value} key={region.value}>
                          {region.label}
                        </option>
                      );
                    })} */}
                    {/* </Field> */}
                  </div>
                  <div className="iv_datepicker" style={{ width: "20rem" }}>
                    <label className="d-block">Date picker</label>

                    <DatePicker
                      placeholder="Pick date"
                      format="DD/MM/YYYY"
                      onChange={onDateChange}
                      maxDate={new Date()}
                      name="startDate"
                      style={{ display: "block", height: "3rem" }}
                      value={stateDate}
                      rangeHover
                      range
                    ></DatePicker>
                  </div>
                  <div className="mt-11">
                    <button
                      className="btn_green btn-sm"
                      type="button"
                      onClick={() => {
                        formProps.resetForm();
                        onResetClick();
                        formProps.setFieldValue("startDate", "");
                        formProps.setFieldValue("endDate", "");
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CardHeaderToolbar>
        <OrderManagementTable />
      </Card>
    </>
  );
}
