import { CardHeader } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Form, Tab, Table, Tabs } from "react-bootstrap";

import {
  Card,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls/Card";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/AssetsHelpers";
import { getDisputeDetailByIdAsync, getLogDetailByIdAsync } from "../../redux";
import moment from "moment";

const DisputeDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getDisputeDetailByIdAsync(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { disputeDetails, logDetails } = useSelector(
    (state) => state.dispute,
    shallowEqual
  );
  const [logData, setLogData] = useState();

  useEffect(() => {
    if (disputeDetails.orderId) {
      dispatch(getLogDetailByIdAsync(disputeDetails.orderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disputeDetails]);

  useEffect(() => {
    if (logDetails.length) {
      const data = logDetails.slice(0, 10);
      setLogData(data);
    }
  }, [logDetails]);

  const handleSelect = (key) => {};

  const viewAll = () => {
    setLogData(logDetails);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <div className="form-group m-0 d-flex align-items-center">
              <Link className="btn btn-link px-0" to="/dispute">
                <SVG
                  className="h-50 align-self-center"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Arrow-left.svg"
                  )}
                />
              </Link>
              <span className="pl-2">Dispute Details</span>
            </div>
          }
        />
        {/* <CardHeaderToolbar>
          {" "}
          <div>
            <div></div>
          </div>
        </CardHeaderToolbar> */}

        <div className="px-6">
          <div className="row align-items-stretch">
            <div className="col-md-6 px-3">
              <div className="info_block mb-6">
                <h3 class="h4">Basic Details</h3>
                <div className="mt-2">
                  <table className="table_basic_user_details">
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Dispute Raised On : </label>
                      </td>
                      <td>
                        <span className="color_blue f-w-850 ml-0">
                          {disputeDetails?.createdAt
                            ? moment(disputeDetails.createdAt).format(
                                "MMM DD, YYYY"
                              )
                            : "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Subject :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {disputeDetails?.title || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Email : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {disputeDetails?.email || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Dispute type : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {disputeDetails?.disputeType || "-"}
                        </span>
                      </td>
                    </tr>
                    {/* <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16">UserId : </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {disputeDetails?.userId || "-"}
                    </span>
                  </td>
                </tr> */}
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">OrderId : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {disputeDetails?.orderDetails?.transactionId || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="">
                      <td colSpan={2}>
                        <label className="fn_s_16">
                          Description :{" "}
                          <Form.Control
                            as="textarea"
                            value={disputeDetails?.description}
                            aria-label="With textarea"
                            rows="5"
                            style={{
                              cursor: "default",
                              width: "32rem",
                              overflow: "auto",
                              fontSize: "16px",
                            }}
                            disabled={true}
                            required
                            className="p-3 mt-4"
                            placeholder="Disput Description"
                          />
                        </label>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-3">
              <div className="info_block mb-6">
                <h3 class="h4">Order Details</h3>
                <div className="mt-2">
                  <table className="table_basic_user_details">
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Date : </label>
                      </td>
                      <td>
                        <span className="color_blue f-w-850">
                          {moment(
                            disputeDetails?.orderDetails?.createdAt
                          ).format("MMM DD, YYYY")}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16 ">Mode : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {disputeDetails?.orderDetails?.mode?.slice(0, 10) ||
                            "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">OrderId :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {disputeDetails?.orderDetails?.transactionId || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Seller First Name : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {disputeDetails?.sellerFirstName || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Seller Last Name : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {disputeDetails?.sellerLastName || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Buyer First Name : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {disputeDetails?.buyerFirstName || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Buyer Last Name : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {disputeDetails?.buyerLastName || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Country : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {disputeDetails?.country || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Payment : </label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {disputeDetails?.paymentMode || "-"}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-12 px-3">
              <div className="info_block mb-6">
                <h3 class="h4">Log Details</h3>
                <div className="mt-5">
                  <Table
                    hover
                    responsive
                    className="mb-0 default_table with_hoverbtn mrpdng_tbl"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Module</th>
                        <th>Platform</th>
                        <th>Activity</th>
                        <th>OrderId</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone No</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logData?.length ? (
                        logData.map((logData, index) => (
                          <tr key={index}>
                            <td style={{ cursor: "pointer" }}>
                              {logData?.createdAt
                                ? moment(logData.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </td>
                            <td className="m_title">
                              {logData?.module || "-"}
                            </td>
                            <td>{logData?.platform || "-"}</td>
                            <td className="m_title">
                              {logData?.activity || "-"}
                            </td>
                            <td>{logData?.transactionId || "-"}</td>
                            <td className="m_title">
                              {logData?.userFirstName || "-"}
                            </td>
                            <td className="m_title">
                              {logData?.userLastName || "-"}
                            </td>
                            <td>{logData?.mobile || "-"}</td>
                            <td>{logData?.email || "-"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {logData?.length > 10 ? (
                    <div className="text-center">
                      <Link onClick={viewAll}>View All Record</Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default DisputeDetails;
