export const ContactUsMap = {
  GET_ALL_CONTACT_START: "GET_ALL_CONTACT_START",
  GET_ALL_CONTACT_SUCCESS: "GET_ALL_CONTACT_SUCCESS",
  GET_ALL_CONTACT_ERROR: "GET_ALL_CONTACT_ERROR",
  ADD_CONTACT_START: "ADD_CONTACT_START",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_ERROR: "ADD_CONTACT_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  CONTACT_FILTERS: "CONTACT_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_CONTACT_LIST: "REFRESH_CONTACT_LIST",
  SET_CONTACT_BATCH_NUMBER: "SET_CONTACT_BATCH_NUMBER",
  SELECT_CONTACT_DETAILS: "SELECT_CONTACT_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  UPDATE_CONTACT_START: "UPDATE_CONTACT_START",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_ERROR: "UPDATE_CONTACT_ERROR",
  DELETE_CONTACT_START: "DELETE_CONTACT_START",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_ERROR: "DELETE_CONTACT_ERROR",
};

export const CONTACTManagementActions = {
  getAllCONTACTStart: (data) => ({
    type: ContactUsMap.GET_ALL_CONTACT_START,
    payload: data,
  }),
  getAllCONTACTSuccess: (data) => ({
    type: ContactUsMap.GET_ALL_CONTACT_SUCCESS,
    payload: data,
  }),
  getAllCONTACTError: (errors) => ({
    type: ContactUsMap.GET_ALL_CONTACT_ERROR,
    payload: { errors },
  }),
  deleteCONTACTStart: (data) => ({
    type: ContactUsMap.DELETE_CONTACT_START,
    payload: data,
  }),
  deleteCONTACTSuccess: (data) => ({
    type: ContactUsMap.DELETE_CONTACT_SUCCESS,
    payload: data,
  }),
  deleteCONTACTError: (errors) => ({
    type: ContactUsMap.DELETE_CONTACT_ERROR,
    payload: { errors },
  }),
  addCONTACTStart: (data) => ({
    type: ContactUsMap.ADD_CONTACT_START,
    payload: data,
  }),
  addCONTACTSuccess: (data) => ({
    type: ContactUsMap.ADD_CONTACT_SUCCESS,
    payload: data,
  }),
  addCONTACTError: (errors) => ({
    type: ContactUsMap.ADD_CONTACT_ERROR,
    payload: { errors },
  }),
  updateCONTACTStart: (data) => ({
    type: ContactUsMap.UPDATE_CONTACT_START,
    payload: data,
  }),
  updateCONTACTSuccess: (data) => ({
    type: ContactUsMap.UPDATE_CONTACT_SUCCESS,
    payload: data,
  }),
  updateCONTACTError: (errors) => ({
    type: ContactUsMap.UPDATE_CONTACT_ERROR,
    payload: { errors },
  }),
  getCONTACTTemplateStart: (data) => ({
    type: ContactUsMap.GET_ALL_TEMPLATES_START,
    payload: data,
  }),
  getCONTACTTemplateSuccess: (data) => ({
    type: ContactUsMap.GET_ALL_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getCONTACTTemplateError: (errors) => ({
    type: ContactUsMap.GET_ALL_TEMPLATES_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: ContactUsMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshCONTACTList: () => ({ type: ContactUsMap.REFRESH_CONTACT_LIST }),
  searchTextChange: (data) => ({
    type: ContactUsMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedCONTACT: (data) => ({
    type: ContactUsMap.SELECT_CONTACT_DETAILS,
    payload: data,
  }),
  setCONTACTBatchNumber: (data) => ({
    type: ContactUsMap.SET_CONTACT_BATCH_NUMBER,
    payload: data,
  }),
  setSortCountry: (data) => ({
    type: ContactUsMap.SET_SORT_CHANGE,
    payload: data,
  }),
};
