import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { DepositManagementActions } from "../../redux";
import { deleteDepositAsync } from "../../redux/DepositManagementApi";
import ReactPaginate from "react-paginate";

const DepositManagementTable = () => {
  const { isLoading, DepositList, skip, searchBy, limit, dir } = useSelector(
    //todo
    (state) => state.DepositManagement,
    shallowEqual
  );
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const dispatch = useDispatch();
  const [selectedDepositName, setselectedDepositName] = useState({});
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(DepositManagementActions.setDepositBatchNumber(count));
  };
  const handleSort = (column, dir) => {
    dispatch(DepositManagementActions.setSortEmail({ column, dir }));
  };

  useEffect(() => {
    handleSort("", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeactiveClick = () => {
    dispatch(deleteDepositAsync(selectedDepositName._id));
    setselectedDepositName({});
    deleteClose();
  };

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (DepositList) {
      setPageCount(Math.ceil(DepositList?.recordsTotal / 10));
      setCount(DepositList.recordsTotal);
    }
  }, [offset, pageCount, DepositList]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    dispatch(DepositManagementActions.setDepositBatchNumber(selectedPage));
  };

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "firstName",
                        dir === "" || dir === "des" ? "asc" : "des"
                      )
                    }
                  >
                    First Name
                    {dir !== "" && searchBy === "firstName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "des" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "lastName",
                        dir === "" || dir === "des" ? "asc" : "des"
                      )
                    }
                  >
                    Last Name
                    {dir !== "" && searchBy === "lastName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "des" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>

                  <th
                    onClick={() =>
                      handleSort(
                        "amount",
                        dir === "" || dir === "des" ? "asc" : "des"
                      )
                    }
                  >
                    Amount{" "}
                    {dir !== "" && searchBy === "amount" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "des" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "accountNumber",
                        dir === "" || dir === "des" ? "asc" : "des"
                      )
                    }
                  >
                    Account Number
                    {dir !== "" && searchBy === "accountNumber" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "des" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "createdAt",
                        dir === "" || dir === "des" ? "asc" : "des"
                      )
                    }
                  >
                    T.Date
                    {dir !== "" && searchBy === "createdAt" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "des" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>

                  <th>T.Hash</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "status",
                        dir === "" || dir === "des" ? "asc" : "des"
                      )
                    }
                  >
                    Status
                    {dir !== "" && searchBy === "status" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "des" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "platform",
                        dir === "" || dir === "des" ? "asc" : "des"
                      )
                    }
                  >
                    Platform
                    {dir !== "" && searchBy === "platform" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "des" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {DepositList.records && DepositList.records.length ? (
                  DepositList.records.map((DepositLists, index) => (
                    <tr key={index}>
                      <td>
                        <span className="">{skip * limit + (index + 1)}</span>
                      </td>
                      <td>
                        <span className="">
                          {DepositLists.firstName || "-"}
                        </span>
                      </td>
                      <td>
                        <span className="">{DepositLists.lastName || "-"}</span>
                      </td>
                      <td>
                        <span className="">{DepositLists.amount || "-"}</span>
                      </td>
                      <td>{DepositLists.accountNumber || "-"}</td>
                      <td>
                        {DepositLists?.createdAt
                          ? moment(DepositLists.createdAt).format(
                              "MMM DD, YYYY"
                            )
                          : "-"}
                      </td>
                      <td>
                        <a
                          href={
                            "https://goerli.etherscan.io/tx/" +
                            DepositLists.transactionHash
                          }
                          target="_blank"
                          className="flex-column-auto mt-5"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {DepositLists.transactionHash
                            ? DepositLists.transactionHash.slice(0, 6) +
                              "..." +
                              DepositLists.transactionHash.slice(-4)
                            : "-"}
                        </a>{" "}
                        .
                      </td>
                      <td className="text-capitalize">
                        {DepositLists.transactionStatus || "-"}
                      </td>
                      <td>{DepositLists.platform || "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={11} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
            <div className="col-md-8 text-right mb-5 aspgntn">
              {count > 10 && pageCount > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  color="primary"
                  variant="outlined"
                />
              )}
            </div>
            <div className="col-md-4 mb-5 fn_s_14 text-right">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {DepositList.recordsTotal} Records
            </div>
          </div>
          {/* {!isLoading && (
            <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={DepositList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_14 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {DepositList.recordsTotal} Records
              </div>
            </div>
          )} */}
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want you to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <button
                type="button"
                className="btn_new btn-sm mr-3"
                onClick={deleteClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn_new btn-sm"
                onClick={onDeactiveClick}
              >
                Yes, Delete
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DepositManagementTable;
