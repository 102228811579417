import { GeneralSettingMap } from "./generalSettingAction";

const initialState = {
  isLoading: false,
  refreshSetting: true,
  cmsLists: {},
  selectedGeneralSetting: {},
  reDirect: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GeneralSettingMap.GET_GENERAL_SETTING_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GeneralSettingMap.GET_GENERAL_SETTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        selectedGeneralSetting: action.payload,
      };
    }
    case GeneralSettingMap.GET_GENERAL_SETTING_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GeneralSettingMap.UPDATE_GENERAL_SETTING_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GeneralSettingMap.UPDATE_GENERAL_SETTING_SUCCESS: {
      return {
        ...state,
        selectedGeneralSetting: {
          ...state.selectedGeneralSetting,
          ...action.payload,
        },
        reDirect: true,
      };
    }
    case GeneralSettingMap.UPDATE_GENERAL_SETTING_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return { ...state };
  }
};
