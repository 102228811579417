import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { PaymentMasterManagementActions } from "./paymentMasterManagementAction";

export const getAllPaymentMasterAsync = (searchBy, searchText, type, dir) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().paymentMasterManagement;
      dispatch(PaymentMasterManagementActions.getAllPaymentMasterStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}/api/paymentMaster/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          PaymentMasterManagementActions.getAllPaymentMasterSuccess(data.data)
        );
      }
      dispatch(PaymentMasterManagementActions.getAllPaymentMasterError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentMasterManagementActions.getAllPaymentMasterError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
export const getAllPaymentMasterTemplatesAsync = (type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(PaymentMasterManagementActions.getPaymentMasterTemplateStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/user/getCountry`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          PaymentMasterManagementActions.getPaymentMasterTemplateSuccess(
            data?.data
          )
        );
      }
      dispatch(PaymentMasterManagementActions.getPaymentMasterTemplateError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentMasterManagementActions.getPaymentMasterTemplateError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getAllPaymentMasterGatewayAsync = (type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(PaymentMasterManagementActions.getPaymentMasterGatewayStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/paymentMaster/getAllPymentGtway`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          PaymentMasterManagementActions.getPaymentMasterGatewaySuccess(
            data.data
          )
        );
      }
      dispatch(PaymentMasterManagementActions.getPaymentMasterGatewayError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentMasterManagementActions.getPaymentMasterGatewayError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addPaymentMasterAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(PaymentMasterManagementActions.addPaymentMasterStart());

      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/paymentMaster/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(
          PaymentMasterManagementActions.addPaymentMasterSuccess(data.data)
        );
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(PaymentMasterManagementActions.addPaymentMasterError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentMasterManagementActions.addPaymentMasterError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updatePaymentMasterAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      let { selectedpaymentmasterlist } = getState().paymentMasterManagement;
      dispatch(PaymentMasterManagementActions.updatePaymentMasterStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}/api/paymentMaster/updateDetails/${selectedpaymentmasterlist._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(
          PaymentMasterManagementActions.updatePaymentMasterSuccess(data.data)
        );
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(PaymentMasterManagementActions.updatePaymentMasterError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentMasterManagementActions.updatePaymentMasterError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deletePaymentMasterAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(PaymentMasterManagementActions.deletePaymentMasterStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/paymentMaster/delete/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(
          PaymentMasterManagementActions.deletePaymentMasterSuccess(data.data)
        );
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(PaymentMasterManagementActions.deletePaymentMasterError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentMasterManagementActions.deletePaymentMasterError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
