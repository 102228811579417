import React from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/users-management",
            false
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/users-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
              />
            </span>
            <span className="menu-text">Users Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/order-management",
            false
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/order-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Import.svg")} />
            </span>
            <span className="menu-text">Order Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/deposit-management",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/deposit-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet2.svg")}
              />
            </span>
            <span className="menu-text">Deposit Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/withdraw-management",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/withdraw-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")} />
            </span>
            <span className="menu-text">Withdraw Management</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/dispute", false)}`}>
          <NavLink className="menu-link" to="/dispute">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Design/Flip-horizontal.svg"
                )}
              />
            </span>
            <span className="menu-text">Dispute</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/paymentMaster", false)}`}
        >
          <NavLink className="menu-link" to="/paymentMaster">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
              />
            </span>
            <span className="menu-text">Set Payment Option</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/country-management",
            false
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/country-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Dial-numbers.svg"
                )}
              />
            </span>
            <span className="menu-text">Country</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/payment-management",
            false
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/payment-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Pound.svg")} />
            </span>
            <span className="menu-text">Payment types</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/cms-management", false)}`}
        >
          <NavLink className="menu-link" to="/cms-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")}
              />
            </span>
            <span className="menu-text">CMS Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/email-management",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/email-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
              />
            </span>
            <span className="menu-text">Email Management</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/contactUs", false)}`}>
          <NavLink className="menu-link" to="/contactUs">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Active-call.svg"
                )}
              />
            </span>
            <span className="menu-text">ContactUs</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/general-setting",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/general-setting">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")} />
            </span>
            <span className="menu-text">Setting</span>
          </NavLink>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
