import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  loadWeb3,
  loadBlockChainData,
  ListenAccountChange,
  listenNetworkChange,
} from "../functions/web3";
import { Web3Provider } from "../contexts/web3Context";

export default function Providers({ children }) {
  const [loading, setLoading] = useState(false);

  const [networkDetails, setNetworkDetails] = useState({
    isUpdated: false,
    address: "",
    provider: "",
    connected: false,
    connectTag: "",
    chainData: "",
    wallet: "",
    chainId: "",
    networkId: "",
    balance: "",
    isAdmin: false,
    ethereum: "",
  });

  const resetApp = async () => {
    setNetworkDetails({
      isUpdated: false,
      address: "",
      provider: "",
      connected: false,
      connectTag: "",
      chainData: "",
      wallet: "",
      chainId: "",
      networkId: "",
      balance: "",
      isAdmin: false,
      ethereum: "",
    });
    const { ethereum } = window;
    // localStorage.clear();
    localStorage.removeItem("injected");
    localStorage.removeItem("wallet_name");
    if (
      ethereum &&
      ethereum.currentProvider &&
      ethereum.currentProvider.disconnect
    ) {
      await ethereum.currentProvider.disconnect();
    }
  };

  const handleConnect = () => {
    const metaMaskInstalled = typeof window.ethereum !== "undefined";
    if (metaMaskInstalled) {
      setLoading(true);

      loadWeb3(setLoading);

      loadBlockChainData(setNetworkDetails, networkDetails, setLoading);

      ListenAccountChange(
        networkDetails,
        setNetworkDetails,
        setLoading,
        resetApp
      );
      listenNetworkChange(
        networkDetails,
        setNetworkDetails,
        setLoading,
        resetApp
      );
    } else {
      toast.info(
        "Metamask Extension Not Found ! Please Install Metamask to Connect"
      );
    }
  };

  useEffect(() => {
    const injected = localStorage.getItem("injected");
    if (injected) {
      const walletName = localStorage.getItem("wallet_name");
      if (walletName && walletName === "metamask") {
        handleConnect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Web3Provider
      value={{
        loadWeb3,
        loading,
        setLoading,
        networkDetails,
        setNetworkDetails,
        loadBlockChainData,
        ListenAccountChange,
        listenNetworkChange,
        handleConnect,
        resetApp,
      }}
    >
      {children}
    </Web3Provider>
  );
}
