import "bootstrap-daterangepicker/daterangepicker.css";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { DepositManagementActions, getAllDepositAsync } from "../redux";

import DatePicker from "react-multi-date-picker";
import DepositManagementTable from "./depositManagementDetail/depositManagementTable";

export function DepositManagement() {
  const dispatch = useDispatch();
  const searchdepositRef = useRef();
  const [stateDate, setStateDate] = useState([]);

  const { refreshDepositList, searchBy, searchText, dir } = useSelector(
    (state) => state.DepositManagement,
    shallowEqual
  );

  const [depositFilter, setDepositFilter] = useState({
    Name: null,
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    dispatch(getAllDepositAsync(searchBy, "", dir, depositFilter));
  }, [dispatch, searchBy, dir, depositFilter]);

  useEffect(() => {
    dispatch(DepositManagementActions.setSelectedDeposit(null));
    if (searchdepositRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(DepositManagementActions.refreshDepositList());
        }
      };
      let input = searchdepositRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(DepositManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (refreshDepositList) {
      dispatch(getAllDepositAsync(searchBy, searchText, ""));
    }
    dispatch(getAllDepositAsync(searchBy, searchText, dir, depositFilter));
  }, [dispatch, refreshDepositList, searchBy, searchText, dir, depositFilter]);

  const onSearchTextChange = (e) => {
    dispatch(DepositManagementActions.searchTextChange(e.target.value));
  };

  const onResetClick = (e) => {
    setDepositFilter({
      ...depositFilter,
      Name: null,
      startDate: null,
      endDate: null,
    });
    setStateDate([]);
    dispatch(DepositManagementActions.searchTextChange(""));
  };
  const onDateChange = (e) => {
    if (e && e[0]) {
      let date = {
        startDate: e[0]
          .toDate()
          .toISOString()
          .slice(0, 10),
        endDate:
          e.length && e.length === 2
            ? e[1]
                .toDate()
                .toISOString()
                .slice(0, 10)
            : e[0]
                .toDate()
                .toISOString()
                .slice(0, 10),
      };
      setDepositFilter({ startDate: date.startDate, endDate: date.endDate });
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Deposit Management" />
        <CardHeaderToolbar className="filter_block">
          <Formik
            initialValues={{
              Name: "",
              startDate: "",
              endDate: "",
            }}
          >
            {(formProps) => (
              <Form>
                <div
                  className="form-group mb-0 p-5"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: "15px",
                  }}
                >
                  <div style={{ width: "20rem" }} className="">
                    <label htmlFor="search">Search By</label>
                    <Field
                      name="search"
                      placeholder="Search By"
                      type="text"
                      className="form-control w-100"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchdepositRef}
                    />
                  </div>

                  <div className="iv_datepicker" style={{ width: "20rem" }}>
                    <label className="w-100">Date picker</label>
                    <DatePicker
                      placeholder="Pick date"
                      format="DD/MM/YYYY"
                      onChange={onDateChange}
                      maxDate={new Date()}
                      name="startDate"
                      style={{ height: "3.1rem" }}
                      value={stateDate}
                      rangeHover
                      range
                    ></DatePicker>
                  </div>
                  <div className="mt-11">
                    <button
                      className="btn_green btn-sm"
                      type="button"
                      onClick={() => {
                        formProps.resetForm();
                        onResetClick();
                        formProps.setFieldValue("startDate", "");
                        formProps.setFieldValue("endDate", "");
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CardHeaderToolbar>
        <DepositManagementTable />
      </Card>
    </>
  );
}
