import { combineReducers } from "redux";
import * as AdminsManagement from "../app/modules/adminManagement/redux";
import * as auth from "../app/modules/Auth/redux/authReducer";
import * as cmsManagement from "../app/modules/cmsManagement/redux";
import * as contactUs from "../app/modules/contactUs/redux";
import * as CountryManagement from "../app/modules/countryManagement/redux";
import * as dashboard from "../app/modules/dashboards/redux";
import * as DepositManagement from "../app/modules/depositManagement/redux";
import * as dispute from "../app/modules/dispute/redux";
import * as emailManagement from "../app/modules/emailManagement/redux";
import * as generalSetting from "../app/modules/general_setting/redux";
import * as environnment from "../app/modules/GetEnvironment/getEnvironmentReducer";
import * as OrderManagement from "../app/modules/orderManagement/redux";
import * as paymentManagement from "../app/modules/paymentManagement/redux";
import * as paymentMasterManagement from "../app/modules/paymentMasterManagement/redux";
import * as withdrawManagement from "../app/modules/withdrawManagement/redux";

import * as profile from "../app/modules/profileSettings/redux";
import * as siteConfiguration from "../app/modules/siteConfiguration/redux";
import snackBarReducer from "../app/modules/snackBar/snackbarReducer";
import * as userManagement from "../app/modules/userManagement/redux";

export const rootReducer = combineReducers({
  snackBar: snackBarReducer,
  environnment: environnment.reducer,
  dashboard: dashboard.reducer,
  auth: auth.reducer,
  userManagement: userManagement.reducer,
  emailManagement: emailManagement.reducer,
  profile: profile.reducer,
  generalSetting: generalSetting.reducer,
  site: siteConfiguration.reducer,
  admin: AdminsManagement.reducer,
  cmsManagement: cmsManagement.reducer,
  paymentManagement: paymentManagement.reducer,
  CountryManagement: CountryManagement.reducer,
  contactUs: contactUs.reducer,
  paymentMasterManagement: paymentMasterManagement.reducer,
  dispute: dispute.reducer,
  OrderManagement: OrderManagement.reducer,
  DepositManagement: DepositManagement.reducer,
  withdrawManagement: withdrawManagement.reducer,
});
