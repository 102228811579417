import { ethers } from "ethers";

const createContractInstance = (wallet, address, abi) => {
  const contract = new ethers.Contract(address, abi, wallet);
  return contract;
};

const createContractWithSigner = (provider, contract) => {
  const signer = provider.getSigner();
  const contractWithSigner = contract.connect(signer);
  return contractWithSigner;
};

const getEthersData = () => {
  let provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_RPC_URL
  );

  let wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);

  return {
    provider,
    wallet,
  };
  //   provider
};

export { createContractInstance, createContractWithSigner, getEthersData };
