import { CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { regexValidation } from "../../../../_metronic/_helpers/RegexValidationHelpers";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Images from "../../../pages/images";
import { adminAsync, updateAdminAsync } from "../redux";

const UpdateAdmin = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    selectedSubAdmin,
    isSubAdminLoading,
    rolePermissionList,
  } = useSelector((state) => state.admin, shallowEqual);

  if (!selectedSubAdmin && params?.id !== undefined) {
    return <Redirect to="/admin" />;
  }
  const initialValues = {
    name: "",
    email: "",
    status: "active",
    roleId: "",
    permissions: [],
    password: "",
    appToken: "",
    profilePicture: selectedSubAdmin.profilePicture || "",
  };
  const redirectBack = () => {
    history.push("/admin");
  };
  const subAdminSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter valid name "
      )
      .required("Please enter name"),
    email: Yup.string()
      .trim()
      .matches(regexValidation.EMAIL_REGEX, "Invalid email")
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter valid email "
      )
      .required("Please enter email"),
    password: Yup.string().trim(),
    roleId: Yup.number()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Role is required"
      )
      .required("Role is required"),
    status: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Status is required"
      )
      .required("Status is required"),
  });
  const status = [
    { value: "", label: "Select Status" },

    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const roles = [
    { value: "", label: "Select Role" },
    { value: 2, label: "Sub Admin" },
    { value: 3, label: "User" },
    { value: 4, label: "Sales" },
  ];
  const goback = () => {
    history.goBack();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          selectedSubAdmin && params?.id !== undefined
            ? selectedSubAdmin
            : initialValues
        }
        validationSchema={subAdminSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let formData = new FormData();

          formData.append("name", values.name);
          formData.append("email", values.email);
          formData.append("status", values.status);
          formData.append("roleId", values.roleId);

          formData.append("password", values.password);
          formData.append("appToken", values.appToken);

          values.permissions.forEach((item) =>
            formData.append("permissions", item)
          );
          if (selectedSubAdmin && params?.id !== undefined) {
            dispatch(
              updateAdminAsync(
                formData,
                params.id,
                setSubmitting,
                resetForm,
                redirectBack
              )
            );
          } else {
            dispatch(
              adminAsync(formData, setSubmitting, resetForm, redirectBack)
            );
          }
          resetForm();
          goback();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Card>
            <CardHeader
              title={
                <div className="form-group m-0 d-flex align-items-center">
                  <button
                    className="btn btn-link px-0"
                    onClick={() => goback()}
                  >
                    <SVG
                      className="h-50 align-self-center"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-left.svg"
                      )}
                    />
                    Back
                  </button>
                  <span className="pl-2">
                    {selectedSubAdmin && params?.id !== undefined
                      ? "Edit Admin"
                      : "Add Admin"}
                  </span>
                </div>
              }
            ></CardHeader>
            <CardBody>
              {isSubAdminLoading && (
                <div className="ml-3 basic-verification-loader text-center">
                  {<CircularProgress />}
                </div>
              )}
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Form>
                    <div className="form-group">
                      <label className="pho_14_500">Name</label>
                      <input
                        placeholder="Enter Name"
                        autoComplete="off"
                        type="text"
                        name="name"
                        className={
                          errors.name && touched.name
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name ? values.name : ""}
                      />
                      {touched.name && errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.name}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        placeholder="Enter Email"
                        autoComplete="off"
                        type="email"
                        name="email"
                        className={
                          errors.email && touched.email
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email ? values.email : ""}
                      />
                      {touched.email && errors.email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.email}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pho_14_500">Password</label>
                      <input
                        placeholder="Enter password"
                        autoComplete="off"
                        type="password"
                        name="password"
                        className={
                          errors.password && touched.password
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password ? values.password : ""}
                      />
                      {touched.password && errors.password ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.password}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pho_14_500">
                        App Token{" "}
                        <a
                          href={Images.appPasswordHelpLink}
                          color="blue"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            height="16px"
                            width={"16px"}
                            src={Images.helpIcon}
                            alt=""
                          ></img>
                        </a>
                      </label>
                      <input
                        placeholder="Enter appToken"
                        autoComplete="off"
                        type="text"
                        name="appToken"
                        className={
                          errors.appToken && touched.appToken
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.appToken ? values.appToken : ""}
                      />
                      {touched.appToken && errors.appToken ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.appToken}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="pho_14_500">Role</label>
                      <Select
                        name="roleId"
                        placeholder="Select Role"
                        className={`form-control border-0 p-0 `}
                        classNamePrefix="phoSelect"
                        options={roles}
                        onChange={(e) => {
                          setFieldValue("roleId", e.value);
                        }}
                        value={[
                          {
                            value: values.roleId ? values.roleId : "",
                            label: roles.find(
                              (element) => element.value === values.roleId
                            )?.label,
                          },
                        ]}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#DCF4E4",
                            primary75: "#DCF4E4",
                            primary50: "#DCF4E4",
                            primary: "#50C878",
                          },
                        })}
                      />
                      {touched.roleId && errors.roleId ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.roleId}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pho_14_500">Status</label>
                      <Select
                        name="status"
                        placeholder="Select Status"
                        className={`form-control border-0 p-0 `}
                        classNamePrefix="phoSelect"
                        options={status}
                        onChange={(e) => {
                          setFieldValue("status", e.value);
                        }}
                        value={[
                          {
                            value: values.status ? values.status : "",
                            label: status.find(
                              (element) => element.value === values.status
                            )?.label,
                          },
                        ]}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#DCF4E4",
                            primary75: "#DCF4E4",
                            primary50: "#DCF4E4",
                            primary: "#50C878",
                          },
                        })}
                      />
                      {touched.status && errors.status ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.status}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Permissions</label>
                      {rolePermissionList && rolePermissionList.length ? (
                        rolePermissionList.map((rolePermission, index) => (
                          <div key={rolePermission._id}>
                            <input
                              key={rolePermission._id}
                              type="checkbox"
                              name="permissions"
                              onChange={handleChange}
                              id={rolePermission._id}
                              value={rolePermission.slug}
                              checked={
                                (values.permissions &&
                                  values.permissions.length) >= 0
                                  ? values.permissions.includes(
                                      rolePermission.slug
                                    )
                                  : ""
                              }
                              style={{ margin: "10px 10px 10px 10px" }}
                            />
                            <span>{rolePermission.title}</span>
                          </div>
                        ))
                      ) : (
                        <div>No Record Found</div>
                      )}
                    </div>

                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="mr-5 mb-1 mt-2">
                        <Link className="btn btn-outline-blue" to="/admin">
                          Close
                        </Link>
                      </div>
                      <div className="mb-1 mt-2">
                        <Button
                          variant="blue"
                          className="px-5 defpddng spinnerBtn"
                          size="lg"
                          type="submit"
                          disabled={isSubAdminLoading}
                        >
                          {selectedSubAdmin && params?.id !== undefined
                            ? "Update"
                            : "Submit"}
                        </Button>
                      </div>
                    </div>
                    <div className="text-center"></div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Formik>
    </>
  );
};
export default UpdateAdmin;
