import { toast } from "react-toastify";
import { ethers, utils } from "ethers";
import { getChainData } from "../helpers/utils";
const loadWeb3 = async (setLoading) => {
  const { ethereum } = window;
  try {
    if (ethereum) {
      await ethereum.request({
        method: "eth_requestAccounts",
        params: [],
      });
    } else {
      alert(
        "WEB3: Non-Ethereum browser detected. you should consider trying MetaMask"
      );
    }
  } catch (err) {
    setLoading(false);
  }
};

const loadBlockChainData = async (
  setNetworkDetails,
  networkDetails,
  setLoading,
  router
) => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const wallet = "metamask";

      setTimeout(async () => {
        const chainId = await ethereum.chainId;

        if (chainId !== null) {
          const chainData = await getChainData(chainId);

          if (chainData !== null) {
            if (chainData?.isChainValid) {
              let accounts = [];
              await ethereum.request({ method: "eth_accounts" }).then((acc) => {
                accounts = acc;
              });

              let balance = "0";
              await ethereum
                .request({
                  method: "eth_getBalance",
                  params: [accounts[0], "latest"],
                })
                .then((bal) => {
                  balance = utils.formatEther(bal);
                });

              localStorage.setItem("injected", "true");
              localStorage.setItem("wallet_name", "metamask");
              // listen the Network ID
              const networkId = await chainData.network_id;
              await setNetworkDetails({
                ...networkDetails,
                address: accounts[0],
                // web3,
                isUpdated: true,
                connected: true,
                wallet,
                chainData,
                chainId,
                networkId,
                balance,
                ethereum,
                provider: new ethers.providers.Web3Provider(ethereum),
              });
              await setLoading(false);

              // eventsListen(router);
            } else if (!chainData?.isChainValid) {
              await ethereum
                .request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0x5" }], // chainId must be in hexadecimal numbers
                })
                .catch(async (error) => {
                  if (error.code === 4902) {
                    await ethereum.request({
                      method: "wallet_addEthereumChain",
                      params: [
                        {
                          chainId: "0x5",
                          // rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
                        },
                      ],
                    });
                  }
                });
            }
          } else {
            await setNetworkDetails({
              isUpdated: true,
              ...networkDetails,
              address: "",
              web3: "",
              connected: false,
              wallet: "",
              chainData: "",
              chainId: "",
              networkId: "",
              balance: "",
              isAdmin: false,
              ethereum,
              provider: new ethers.providers.Web3Provider(ethereum),
            });
            // localStorage.clear()
            await setLoading(false);
          }
        }
      }, 2000);
    } else {
      alert(
        "WEB3: Non-Ethereum browser detected. you should consider trying MetaMask"
      );
    }
  } catch (err) {
    setLoading(false);
  }
};

const ListenAccountChange = async (
  networkDetails,
  setNetworkDetails,
  setLoading,
  resetApp
) => {
  try {
    const { ethereum } = window;
    ethereum.on("accountsChanged", async function(newAccounts) {
      setLoading(true);
      const { chainId } = ethereum;
      const chainData = await getChainData(chainId);
      const networkId = chainData.network_id;
      if (newAccounts.length !== 0) {
        let balance = "0";
        ethereum
          .request({
            method: "eth_getBalance",
            params: [newAccounts[0], "latest"],
          })
          .then((bal) => {
            balance = utils.formatEther(bal);
          });

        localStorage.setItem("injected", "true");
        localStorage.setItem("wallet_name", "metamask");
        setNetworkDetails((prevState) => ({
          ...prevState,
          address: newAccounts[0],
          balance,
          chainId,
          networkId,
          chainData,
          // web3,
          connected: true,
          ethereum,
          provider: new ethers.providers.Web3Provider(ethereum),
        }));
        setLoading(false);
      } else {
        setLoading(false);
        resetApp();
      }
    });
    // }
  } catch (err) {
    setLoading(false);
  }
};

const listenNetworkChange = (
  networkDetails,
  setNetworkDetails,
  setLoading,
  resetApp
) => {
  try {
    const { ethereum } = window;
    ethereum.on("chainChanged", async function(cid) {
      const { chainId } = ethereum;

      const chainData = await getChainData(chainId);
      const networkId = chainData.network_id;

      if (chainData && chainData.isChainValid) {
        setNetworkDetails((prevState) => ({
          ...prevState,
          chainId,
          networkId,
          chainData,
        }));
        setLoading(false);
      } else {
        resetApp();
        toast.warning("Network not supported");
        setLoading(false);
      }
    });
    // }
  } catch (err) {
    setLoading(false);
  }
};

export {
  loadWeb3,
  loadBlockChainData,
  ListenAccountChange,
  listenNetworkChange,
};
