import { CardHeader } from "@material-ui/core";
import React, { useEffect } from "react";
import SVG from "react-inlinesvg";

import {
  Card,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls/Card";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/AssetsHelpers";
import { getOrderDetailByIdAsync } from "../../redux/OrderManagementApi";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const { orderDetails } = useSelector(
    (state) => state.OrderManagement,
    shallowEqual
  );

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailByIdAsync(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card>
      <CardHeader
        title={
          <div className="form-group m-0 d-flex align-items-center">
            <Link className="btn btn-link px-0" to="/order-management">
              <SVG
                className="h-50 align-self-center"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Arrow-left.svg"
                )}
              />
            </Link>
            <span className="pl-2">Order Details</span>
          </div>
        }
      />
      <CardHeaderToolbar>
        {" "}
        <div>
          <div></div>
        </div>
      </CardHeaderToolbar>
      <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
        <div className="tblmrgn mt-0">
          <div className="mt-4">
            <div className="row">
              <div className="col-md-6 px-3">
                <div className="info_block mb-6">
                  <table className="table_basic_user_details">
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Date :</label>
                      </td>
                      <td>
                        <strong className="text_ltblue">
                          {orderDetails?.createdAt
                            ? moment(orderDetails.createdAt).format(
                                "MMM DD, YYYY"
                              )
                            : "-"}
                        </strong>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">OrderId :</label>
                      </td>
                      <td>
                        <strong className="text_green">
                          {orderDetails?._id ? orderDetails.transactionId : "-"}
                        </strong>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Mode :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.mode || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Price :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {orderDetails?.price || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Volume :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {orderDetails?.volume || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Currency :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {orderDetails?.currency || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Country :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.country || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Payment Mode :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.paymentMode || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Token Name :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.tokenName || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Token Address :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {orderDetails?.tokenAddress || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Seller Name :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.sellerFirstName || "-"}{" "}
                          {orderDetails?.sellerLastName || ""}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Seller Address :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {orderDetails?.sellerAddress || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Buyer Name :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.buyerFirstName || "-"}{" "}
                          {orderDetails?.buyerLastName || ""}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Buyer Address :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0">
                          {orderDetails?.buyerAddress || "-"}
                        </span>
                      </td>
                    </tr>

                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Status :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.status || "-"}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-6 px-3">
                <div className="info_block mb-6">
                  <table className="table_basic_user_details">
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Bank Name :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.bankDetail?.name || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Bank Code :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.bankDetail?.bankCode || "-"}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <label className="fn_s_16">Bank Account :</label>
                      </td>
                      <td>
                        <span className="color_main f-w-850 ml-0 m_title">
                          {orderDetails?.bankDetail?.account || "-"}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OrderDetails;
