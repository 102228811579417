import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { OrderManagementActions } from "../../redux";
import moment from "moment";
import ReactPaginate from "react-paginate";

const OrderManagementTable = () => {
  const { isLoading, OrderList, skip, limit, dir, searchBy } = useSelector(
    (state) => state.OrderManagement,
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(OrderManagementActions.setOrderBatchNumber(count));
  };
  const handleSort = (column, dir) => {
    dispatch(OrderManagementActions.setSortEmail({ column, dir }));
  };

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  useEffect(
    () => handleSort("", ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (OrderList) {
      setPageCount(Math.ceil(OrderList?.recordsTotal / 10));
      setCount(OrderList.recordsTotal);
    }
  }, [offset, pageCount, OrderList]);

  const onUserDetailClick = (order) => {
    history.push(`/order-details/${order._id}`);
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    dispatch(OrderManagementActions.setOrderBatchNumber(selectedPage));
  };

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "mode",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Mode
                    {dir !== "" && searchBy === "mode" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "country",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Country
                    {dir !== "" && searchBy === "country" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "paymentMode",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Payment Gateway
                    {dir !== "" && searchBy === "paymentMode" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "price",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Price
                    {dir !== "" && searchBy === "price" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "currency",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Currency
                    {dir !== "" && searchBy === "currency" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "volume",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Volume
                    {dir !== "" && searchBy === "volume" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th>Total</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "createdAt",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Date
                    {dir !== "" && searchBy === "createdAt" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "status",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Status
                    {dir !== "" && searchBy === "status" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {OrderList?.records && OrderList?.records.length ? (
                  OrderList?.records.map((OrderLists, index) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => onUserDetailClick(OrderLists)}
                    >
                      <td>
                        <span className="">{skip * limit + (index + 1)}</span>
                      </td>
                      <td className="m_title">
                        {/* <span className="badge bg-secondary">{OrderLists.mode}</span> */}
                        <span
                          className="badge text-white"
                          style={{
                            backgroundColor:
                              OrderLists?.mode === "sell"
                                ? "#9250f1"
                                : "#0dc990",
                          }}
                        >
                          {OrderLists.mode}
                        </span>
                      </td>
                      <td>{OrderLists.country}</td>
                      <td>{OrderLists.paymentMode}</td>
                      <td>
                        <span className="">{OrderLists.price}</span>
                      </td>
                      <td>{OrderLists.currency}</td>
                      <td>{OrderLists.volume.toFixed(2)}</td>
                      <td>
                        {(OrderLists.volume * OrderLists.price).toFixed(2)}
                      </td>
                      <td>
                        {moment(OrderLists.createdAt).format("MMM DD, YYYY")}
                      </td>
                      {/* <td className="m_title">{OrderLists.status}</td> */}
                      <td className="m_title fn_s_13">
                        <span
                          style={{
                            color:
                              OrderLists?.status === "pending"
                                ? "#f00"
                                : "#0876eb",
                          }}
                        >
                          {OrderLists.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
            <div className="col-md-8 text-right mb-5 aspgntn pl-1">
              {count > 10 && pageCount > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  color="primary"
                  variant="outlined"
                />
              )}
            </div>
            <div className="col-md-4 mb-5 fn_s_14 text-right ">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {OrderList?.recordsTotal} Records
            </div>
          </div>
          {/* {!isLoading && (
            <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={OrderList?.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_14 text-right ">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {OrderList?.recordsTotal} Records
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default OrderManagementTable;
