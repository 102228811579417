import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { DISPUTEManagementActions, getAllDISPUTEAsync } from "../redux";
import DisputeTable from "./DisputeDetail/disputeDetailTable";

export function DisputeManagement() {
  const dispatch = useDispatch();
  const searchDISPUTERef = useRef();
  const { refreshDISPUTEList, searchBy, searchText, dir } = useSelector(
    (state) => state.dispute,
    shallowEqual
  );
  useEffect(() => {
    dispatch(DISPUTEManagementActions.setSelectedDISPUTE(null));
    if (searchDISPUTERef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(DISPUTEManagementActions.refreshDISPUTEList());
        }
      };
      let input = searchDISPUTERef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(DISPUTEManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);
  useEffect(() => {
    if (refreshDISPUTEList) {
      dispatch(getAllDISPUTEAsync(searchBy, searchText, ""));
    }
    dispatch(getAllDISPUTEAsync(searchBy, searchText, dir));
  }, [dispatch, refreshDISPUTEList, searchBy, searchText, dir]);

  const onSearchTextChange = (e) => {
    dispatch(DISPUTEManagementActions.searchTextChange(e.target.value));
  };
  return (
    <>
      <Card>
        <CardHeader title="Dispute">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchDISPUTERef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <DisputeTable />
      </Card>
    </>
  );
}
