import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import UpdateSubAdmin from "./modules/adminManagement/pages/createAdmin";
// import { BatchUpload } from "./modules/batchUpload/pages/batchManagement";
import { CMSManagement } from "./modules/cmsManagement";
import AddTemplateCMS from "./modules/cmsManagement/pages/AddTemplateCMS";
import { ContactUs } from "./modules/contactUs";
import { CountryManagement } from "./modules/countryManagement/pages/countryManagement";
import { DisputeManagement } from "./modules/dispute/pages/disputeManagement";
import { EmailManagement } from "./modules/emailManagement";
import AddTemplate from "./modules/emailManagement/pages/AddTemplate";
import { OrderManagement } from "./modules/orderManagement/pages/OrderManagement";
import AddPayment from "./modules/paymentManagement/pages/AddPayment";
import { PaymentManagement } from "./modules/paymentManagement/pages/paymentManagement";
import { PaymentMaster } from "./modules/paymentMasterManagement";
import AddPaymentMaster from "./modules/paymentMasterManagement/pages/AddPaymentMaster";
import { ProfileSettings } from "./modules/profileSettings";
import { SiteConfiguration } from "./modules/siteConfiguration";
import { UserManagement } from "./modules/userManagement/pages/userManagement";
import { WithdrawManagement } from "./modules/withdrawManagement/pages/WithdrawManagement";
import { DepositManagement } from "./modules/depositManagement/pages/depositManagement";
import { DashboardPage } from "./pages/DashboardPage";
import OrderDetails from "./modules/orderManagement/pages/orderManagementDetail/orderDetails";
import UserBasicDetails from "./modules/userManagement/pages/userManagementDetail/userBasicDetails";
import DisputeDetails from "./modules/dispute/pages/DisputeDetail/disputeDetails";
import { GeneralSettingPages } from "./modules/general_setting/pages/generalSetting";
import UserTradingDetails from "./modules/userManagement/pages/userManagementDetail/userTradingDetails";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute
          path="/dashboard"
          component={DashboardPage}
          title="Dashboard"
        />
        <ContentRoute
          path="/profile-settings"
          component={ProfileSettings}
          title="Profile Settings"
        />
        <ContentRoute
          path="/users-management"
          component={UserManagement}
          title="User Management"
        />

        <ContentRoute
          path="/user-details/:id"
          component={UserBasicDetails}
          title="User Details"
        />
        <ContentRoute
          path="/user-trading-details/:id"
          component={UserTradingDetails}
          title="User Trading Details"
        />

        <ContentRoute
          path="/order-management"
          component={OrderManagement}
          title="Order Management"
        />

        <ContentRoute
          path="/order-details/:id"
          component={OrderDetails}
          title="Order Details"
        />

        {/* <ContentRoute
          exact
          path="/admin"
          component={AdminsManagement}
          title="Admin Management"
        /> */}
        <ContentRoute
          exact
          path="/admin/add-admin"
          component={UpdateSubAdmin}
          title="Add Admin"
        />
        <ContentRoute
          exact
          path="/admin/edit-admin/:id"
          component={UpdateSubAdmin}
          title="Update Admin"
        />
        <ContentRoute
          exact
          path="/site-configuration"
          component={SiteConfiguration}
          title="Site Configuration"
        />
        <ContentRoute
          exact
          path="/email-management"
          component={EmailManagement}
          title="Email Management"
        />
        <ContentRoute
          exact
          path="/cms-management"
          component={CMSManagement}
          title="CMS Management"
        />

        <ContentRoute
          exact
          path="/payment-management"
          component={PaymentManagement}
          title="Payment Types"
        />
        <ContentRoute
          exact
          path="/payment-management/add-payment"
          component={AddPayment}
          title="Payment Types"
        />
        <ContentRoute
          exact
          path="/payment-management/edit-payment/:id"
          component={AddPayment}
          title="Update Payment"
        />

        <ContentRoute
          exact
          path="/cms-management/add-template"
          component={AddTemplateCMS}
          title="CMS Management"
        />
        <ContentRoute
          exact
          path="/cms-management/edit-template/:id"
          component={AddTemplateCMS}
          title="Update CMS"
        />
        <ContentRoute
          exact
          path="/email-management/add-template"
          component={AddTemplate}
          title="Add Email"
        />
        <ContentRoute
          exact
          path="/email-management/edit-template/:id"
          component={AddTemplate}
          title="Update Email"
        />
        <ContentRoute
          exact
          path="/country-management"
          component={CountryManagement}
          title="Country Management"
        />
        <ContentRoute
          exact
          path="/ContactUs"
          component={ContactUs}
          title="ContactUs"
        />
        <ContentRoute
          exact
          path="/dispute"
          component={DisputeManagement}
          title="Dispute"
        />
        <ContentRoute
          exact
          path="/dispute-details/:id"
          component={DisputeDetails}
          title="Dispute Details"
        />
        <ContentRoute
          exact
          path="/paymentMaster"
          component={PaymentMaster}
          title="paymentMaster"
        />

        <ContentRoute
          exact
          path="/paymentMaster/add-payment-master"
          component={AddPaymentMaster}
          title="Add Payment Master"
        />
        <ContentRoute
          exact
          path="/paymentMaster/add-payment-master/:id"
          component={AddPaymentMaster}
          title="Update Payment Master"
        />
        <ContentRoute
          exact
          path="/withdraw-management"
          component={WithdrawManagement}
          title="Withdraw Management"
        />
        <ContentRoute
          exact
          path="/deposit-management"
          component={DepositManagement}
          title="Deposit Management"
        />
        <ContentRoute
          exact
          path="/general-setting"
          component={GeneralSettingPages}
          title="General Setting"
        />
        {/*<ContentRoute
          exact
          path="/batch-management"
          component={BatchUpload}
          title="Batch Upload"
        />
        <ContentRoute
          exact
          path="/batch-management/sendEmail"
          component={SendEmail}
          title="Send Email"
        />
        <ContentRoute
          exact
          path="/batch-management/viewHistory"
          component={ViewHistory}
          title="View History"
        /> */}
        {/* <ContentRoute
          exact
          path="/batch-management/add"
          component={ImportCSV}
          title="Import CSV"
        /> */}

        <ContentRoute
          exact
          path="/general-setting"
          component={GeneralSettingPages}
          title="General Setting"
        />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
