import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { WithdrawManagementActions } from "./WithdrawManagementAction";

export const getAllWithdrawAsync = (
  searchBy,
  searchText,
  dir,
  WithdrawFilter
) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().withdrawManagement;
      dispatch(WithdrawManagementActions.getAllWithdrawStart());
      const { data } = await axios({
        method: "Post",
        url: `${process.env.REACT_APP_HOST}/api/pool/withdraw/list?skip=${skip *
          10}&limit=${limit}&column=${searchBy}&search=${searchText}&dir=${dir}`,
        data: {
          ...WithdrawFilter,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          WithdrawManagementActions.getAllWithdrawSuccess(data.data)
        );
      }
      dispatch(WithdrawManagementActions.getAllWithdrawError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(WithdrawManagementActions.getAllWithdrawError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
export const getAllWithdrawCountryListsAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(WithdrawManagementActions.getWithdrawCountryListStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/user/getCountry`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          WithdrawManagementActions.getWithdrawCountryListSuccess(data.data)
        );
      }
      dispatch(WithdrawManagementActions.getWithdrawCountryListError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(WithdrawManagementActions.getWithdrawCountryListError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateWithdrawStatusAsync = (
  id,
  value,
  setWithdrawData,
  reason
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(WithdrawManagementActions.updateWithdrawStatusStart());
      const { data } = await axios({
        method: "Put",
        url: `${process.env.REACT_APP_HOST}/api/admin/withdraw/updateDetails/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          status: value.status,
          signature: value.signature,
          reason: value.reason,
        },
      });
      if (data.code === 200) {
        dispatch(
          WithdrawManagementActions.updateWithdrawStatusSuccess(data.data)
        );
        setWithdrawData();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(WithdrawManagementActions.updateWithdrawStatusError());
      setWithdrawData();
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      setWithdrawData();
      dispatch(WithdrawManagementActions.updateWithdrawStatusError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getAllWithdrawGatewayListsAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(WithdrawManagementActions.getWithdrawGatewayListStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/paymentGateway/getPayment`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          WithdrawManagementActions.getWithdrawGatewayListSuccess(data.data)
        );
      }
      dispatch(WithdrawManagementActions.getWithdrawGatewayListError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(WithdrawManagementActions.getWithdrawGatewayListError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addWithdrawAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(WithdrawManagementActions.addWithdrawStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/cms/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(WithdrawManagementActions.addWithdrawSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(WithdrawManagementActions.addWithdrawError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(WithdrawManagementActions.addWithdrawError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateWithdrawAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      let { selectedWithdraw } = getState().WithdrawManagement;
      dispatch(WithdrawManagementActions.updateWithdrawStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}/api/cms/updateDetails/${selectedWithdraw._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(WithdrawManagementActions.updateWithdrawSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(WithdrawManagementActions.updateWithdrawError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(WithdrawManagementActions.updateWithdrawError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteWithdrawAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(WithdrawManagementActions.deleteWithdrawStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/cms/delete/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(WithdrawManagementActions.deleteWithdrawSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(WithdrawManagementActions.deleteWithdrawError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(WithdrawManagementActions.deleteWithdrawError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
