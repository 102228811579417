import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import ReactPaginate from "react-paginate";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { PaymentManagementActions } from "../../redux";
import { deletePAYMENTAsync } from "../../redux/PaymentManagementApi";

const PaymentManagementTable = () => {
  const { isLoading, PAYMENTList, skip, limit, dir, searchBy } = useSelector(
    (state) => state.paymentManagement,
    shallowEqual
  );
  const history = useHistory();
  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);
  const dispatch = useDispatch();
  const [selectedPAYMENTName, setselectedPAYMENTName] = useState({});
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(PaymentManagementActions.setPAYMENTBatchNumber(count));
  };
  const handleSort = (column, dir) => {
    dispatch(PaymentManagementActions.setSortEmail({ column, dir }));
  };
  const onEditClick = (PAYMENTLists) => {
    dispatch(PaymentManagementActions.setSelectedPAYMENT(PAYMENTLists));
    history.push(`/payment-management/edit-payment/${PAYMENTLists?._id}`);
  };
  const onDeleteClick = (PAYMENTLists) => {
    setselectedPAYMENTName(PAYMENTLists);
    deleteShow();
  };

  useEffect(
    () => handleSort("", ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDeactiveClick = () => {
    dispatch(deletePAYMENTAsync(selectedPAYMENTName._id));
    setselectedPAYMENTName({});
    deleteClose();
  };

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (PAYMENTList) {
      setPageCount(Math.ceil(PAYMENTList?.recordsTotal / 10));
      setCount(PAYMENTList.recordsTotal);
    }
  }, [offset, pageCount, PAYMENTList]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    dispatch(PaymentManagementActions.setPAYMENTBatchNumber(selectedPage));
  };

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "name",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Payment Name{" "}
                    {dir !== "" && searchBy === "name" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th width="120px">Action</th>
                </tr>
              </thead>
              <tbody>
                {PAYMENTList.records && PAYMENTList.records.length ? (
                  PAYMENTList.records.map((PAYMENTLists, index) => (
                    <tr key={index}>
                      <td>
                        <span className="">{skip * limit + (index + 1)}</span>
                      </td>
                      <td>
                        <span className="">{PAYMENTLists.name}</span>
                      </td>

                      <td>
                        <div className="d-flex">
                          <button
                            title="Edit"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                            onClick={() => onEditClick(PAYMENTLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </button>
                          <button
                            title="Delete"
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => onDeleteClick(PAYMENTLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
            <div className="col-md-8 mb-5 aspgntn">
              {count > 10 && pageCount > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  color="primary"
                  variant="outlined"
                />
              )}
            </div>
            <div className="col-md-4 mb-5 fn_s_14 text-right">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {PAYMENTList.recordsTotal} Records
            </div>
          </div>
          {/* {!isLoading && (
            <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
              <div className="col-md-8 mb-5 aspgntn">
                <BasicPagination
                  totalRecords={PAYMENTList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_14 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {PAYMENTList.recordsTotal} Records
              </div>
            </div>
          )} */}
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want you to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <button
                type="button"
                className="btn_new btn-sm mr-3"
                onClick={deleteClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn_new btn-sm"
                onClick={onDeactiveClick}
              >
                Yes, Delete
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentManagementTable;
