import { Form, Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { addCMSAsync, updateCMSAsync } from "../redux/CMSManagementApi";

const AddTemplateCMS = () => {
  const templateOptions = [];
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let Selected = {};

  const entities = [
    { values: "web", label: "WEB" },
    { values: "mobile", label: "MOBILE" },
  ];
  const { selectedCMS, templates } = useSelector(
    (state) => state.cmsManagement,
    shallowEqual
  );

  // const CMS_URl_Regex = /(((https?):\/\/)(www.)?)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  const CMS_URl_Regex = /^(wwww|http|https):\/\/[^ "]+$/;

  // TODO : This cms edit
  if (selectedCMS?._id) {
    Selected = {
      value: selectedCMS?.platform,
      label: selectedCMS?.platform.toUpperCase(),
    };
  } else {
    Selected = { value: entities[0].values, label: entities[0].label };
  }

  if (!selectedCMS && params?.id !== undefined) {
    return <Redirect to="/cms-management" />;
  }
  if (selectedCMS && params?.id && templates) {
    templates.forEach((template) => {
      if (selectedCMS.name && template.platform === selectedCMS.description) {
        template.templateEntities.forEach((entity) => {
          entities.push({ value: entity, label: entity });
        });
      }
    });
  }
  const initialValues = {
    name: "",
    platform: selectedCMS?.platform || entities[0].value,
    description: "",
  };

  if (templates && templates.length > 0) {
    initialValues.templates = templates;
    templates.forEach((entity) => {
      templateOptions.push({
        value: entity.templateName,
        label: entity.templateName,
      });
    });
  }
  const redirectBack = () => {
    history.push("/cms-management");
  };

  const addEmailSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter Email Title"
      )
      .required("CMS Name is required")
      .matches(/^(\S+$)/, "* This field cannot contain blankspaces"),

    description: Yup.string()
      .trim()
      .required("Please enter CMS URL Link")
      .matches(CMS_URl_Regex, "Invalid URL format"),
  });
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          selectedCMS && params?.id !== undefined ? selectedCMS : initialValues
        }
        validationSchema={addEmailSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (selectedCMS && params?.id !== undefined) {
            dispatch(
              updateCMSAsync(values, setSubmitting, resetForm, redirectBack)
            );
          } else {
            dispatch(
              addCMSAsync(values, setSubmitting, resetForm, redirectBack)
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Card>
            <CardHeader
              title={
                <div className="form-group m-0 d-flex align-items-center">
                  <Link className="btn btn-link px-0" to="/cms-management">
                    <SVG
                      className="h-50 align-self-center"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-left.svg"
                      )}
                    />
                    Back
                  </Link>
                  <span className="pl-2">
                    {selectedCMS ? "Edit CMS" : "Add CMS"}
                  </span>
                </div>
              }
            ></CardHeader>
            <CardBody>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Form>
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        placeholder="Name"
                        type="text"
                        // disabled={selectedCMS && params?.id !== undefined}
                        name="name"
                        className={
                          errors.name && touched.name
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {touched.name && errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.name}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="form-label">URL</label>
                      <input
                        placeholder="URL"
                        type="link"
                        name="description"
                        className={
                          errors.description && touched.description
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                      {touched.description && errors.description ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.description}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pho_14_500">Platform</label>

                      <Select
                        placeholder="Select CMS"
                        className={`form-control border-0 p-0 `}
                        classNamePrefix="phoSelect"
                        options={entities}
                        defaultValue={[Selected]}
                        onChange={(e) => {
                          setFieldValue("platform", e.values);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#DCF4E4",
                            primary75: "#DCF4E4",
                            primary50: "#DCF4E4",
                            primary: "#50C878",
                          },
                        })}
                      />
                      {touched.mailName && errors.mailName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.mailName}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="mr-5 mb-1 mt-2">
                        <Link
                          className="btn btn-outline-blue"
                          to="/cms-management"
                        >
                          Close
                        </Link>
                      </div>
                      <div className="mb-1 mt-2">
                        <Button
                          variant="blue"
                          className="px-5 defpddng spinnerBtn"
                          size="lg"
                          type="submit"
                        >
                          {selectedCMS && params?.id !== undefined
                            ? "Update"
                            : "Submit"}
                          {isSubmitting && (
                            <div className="ml-3 basic-verification-loader text-center"></div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Formik>
    </>
  );
};
export default AddTemplateCMS;
