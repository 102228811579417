export const PaymentMasterMap = {
  GET_ALL_PAYMENTMASTER_START: "GET_ALL_PAYMENTMASTER_START",
  GET_ALL_PAYMENTMASTER_SUCCESS: "GET_ALL_PAYMENTMASTER_SUCCESS",
  GET_ALL_PAYMENTMASTER_ERROR: "GET_ALL_PAYMENTMASTER_ERROR",
  ADD_PAYMENTMASTER_START: "ADD_PAYMENTMASTER_START",
  ADD_PAYMENTMASTER_SUCCESS: "ADD_PAYMENTMASTER_SUCCESS",
  ADD_PAYMENTMASTER_ERROR: "ADD_PAYMENTMASTER_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_PAYMENTMASTER_LIST: "REFRESH_PAYMENTMASTER_LIST",
  SET_PAYMENTMASTER_BATCH_NUMBER: "SET_PAYMENTMASTER_BATCH_NUMBER",
  SELECT_PAYMENTMASTER_DETAILS: "SELECT_PAYMENTMASTER_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  GET_ALL_GATEWAY_TEMPLATES_START: "GET_ALL_GATEWAY_TEMPLATES_START",
  GET_ALL_GATEWAY_TEMPLATES_SUCCESS: "GET_ALL_GATEWAY_TEMPLATES_SUCCESS",
  GET_ALL_GATEWAY_TEMPLATES_ERROR: "GET_ALL_GATEWAY_TEMPLATES_ERROR",
  UPDATE_PAYMENTMASTER_START: "UPDATE_PAYMENTMASTER_START",
  UPDATE_PAYMENTMASTER_SUCCESS: "UPDATE_PAYMENTMASTER_SUCCESS",
  UPDATE_PAYMENTMASTER_ERROR: "UPDATE_PAYMENTMASTER_ERROR",
  DELETE_PAYMENTMASTER_START: "DELETE_PAYMENTMASTER_START",
  DELETE_PAYMENTMASTER_SUCCESS: "DELETE_PAYMENTMASTER_SUCCESS",
  DELETE_PAYMENTMASTER_ERROR: "DELETE_PAYMENTMASTER_ERROR",
};

export const PaymentMasterManagementActions = {
  getAllPaymentMasterStart: (data) => ({
    type: PaymentMasterMap.GET_ALL_PAYMENTMASTER_START,
    payload: data,
  }),
  getAllPaymentMasterSuccess: (data) => ({
    type: PaymentMasterMap.GET_ALL_PAYMENTMASTER_SUCCESS,
    payload: data,
  }),
  getAllPaymentMasterError: (errors) => ({
    type: PaymentMasterMap.GET_ALL_PAYMENTMASTER_ERROR,
    payload: { errors },
  }),
  deletePaymentMasterStart: (data) => ({
    type: PaymentMasterMap.DELETE_PAYMENTMASTER_START,
    payload: data,
  }),
  deletePaymentMasterSuccess: (data) => ({
    type: PaymentMasterMap.DELETE_PAYMENTMASTER_SUCCESS,
    payload: data,
  }),
  deletePaymentMasterError: (errors) => ({
    type: PaymentMasterMap.DELETE_PAYMENTMASTER_ERROR,
    payload: { errors },
  }),
  addPaymentMasterStart: (data) => ({
    type: PaymentMasterMap.ADD_PAYMENTMASTER_START,
    payload: data,
  }),
  addPaymentMasterSuccess: (data) => ({
    type: PaymentMasterMap.ADD_PAYMENTMASTER_SUCCESS,
    payload: data,
  }),
  addPaymentMasterError: (errors) => ({
    type: PaymentMasterMap.ADD_PAYMENTMASTER_ERROR,
    payload: { errors },
  }),
  updatePaymentMasterStart: (data) => ({
    type: PaymentMasterMap.UPDATE_PAYMENTMASTER_START,
    payload: data,
  }),
  updatePaymentMasterSuccess: (data) => ({
    type: PaymentMasterMap.UPDATE_PAYMENTMASTER_SUCCESS,
    payload: data,
  }),
  updatePaymentMasterError: (errors) => ({
    type: PaymentMasterMap.UPDATE_PAYMENTMASTER_ERROR,
    payload: { errors },
  }),
  getPaymentMasterTemplateStart: (data) => ({
    type: PaymentMasterMap.GET_ALL_TEMPLATES_START,
    payload: data,
  }),
  getPaymentMasterTemplateSuccess: (data) => ({
    type: PaymentMasterMap.GET_ALL_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getPaymentMasterTemplateError: (errors) => ({
    type: PaymentMasterMap.GET_ALL_TEMPLATES_ERROR,
    payload: { errors },
  }),
  //
  getPaymentMasterGatewayStart: (data) => ({
    type: PaymentMasterMap.GET_ALL_GATEWAY_TEMPLATES_START,
    payload: data,
  }),
  getPaymentMasterGatewaySuccess: (data) => ({
    type: PaymentMasterMap.GET_ALL_GATEWAY_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getPaymentMasterGatewayError: (errors) => ({
    type: PaymentMasterMap.GET_ALL_GATEWAY_TEMPLATES_ERROR,
    payload: { errors },
  }),
  //
  applyFilters: (data) => ({
    type: PaymentMasterMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshPaymentMasterList: () => ({ type: PaymentMasterMap.REFRESH_PAYMENTMASTER_LIST }),
  searchTextChange: (data) => ({
    type: PaymentMasterMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedPaymentMaster: (data) => ({
    type: PaymentMasterMap.SELECT_PAYMENTMASTER_DETAILS,
    payload: data,
  }),
  setPaymentMasterBatchNumber: (data) => ({
    type: PaymentMasterMap.SET_PAYMENTMASTER_BATCH_NUMBER,
    payload: data,
  }),
  setSortPaymentMaster: (data) => ({
    type: PaymentMasterMap.SET_SORT_CHANGE,
    payload: data,
  }),
};
