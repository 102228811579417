import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  getAllPaymentMasterAsync,
  PaymentMasterManagementActions,
} from "../redux";
import PaymentMasterTable from "./paymentMasterManagement/paymentMasterTable";

export function PaymentMaster() {
  const dispatch = useDispatch();
  const searchPaymentRef = useRef();
  const history = useHistory();
  const {
    refreshpaymentmasterlist,
    searchBy,
    searchText,
    dir,
    column,
  } = useSelector((state) => state.paymentMasterManagement, shallowEqual);
  useEffect(() => {
    dispatch(PaymentMasterManagementActions.setSelectedPaymentMaster(null));
    if (searchPaymentRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(PaymentMasterManagementActions.refreshPaymentMasterList());
        }
      };
      let input = searchPaymentRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(PaymentMasterManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);
  useEffect(() => {
    if (refreshpaymentmasterlist) {
      dispatch(getAllPaymentMasterAsync(searchBy, searchText, "", dir));
    }
    dispatch(getAllPaymentMasterAsync(searchBy, searchText, "", dir));
  }, [dispatch, refreshpaymentmasterlist, searchBy, searchText, column, dir]);

  const onAddClick = () => {
    history.push("/paymentMaster/add-payment-master");
  };

  const onSearchTextChange = (e) => {
    dispatch(PaymentMasterManagementActions.searchTextChange(e.target.value));
  };
  return (
    <>
      <Card>
        <CardHeader title="Set Payment Option">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_new btn-sm"
                      type="button"
                      onClick={() => onAddClick()}
                    >
                      Add
                    </button>
                  </div>
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchPaymentRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <PaymentMasterTable />
      </Card>
    </>
  );
}
