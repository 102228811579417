export const WithdrawManagementMap = {
  GET_ALL_WITHDRAW_START: "GET_ALL_WITHDRAW_START",
  GET_ALL_WITHDRAW_SUCCESS: "GET_ALL_WITHDRAW_SUCCESS",
  GET_ALL_WITHDRAW_ERROR: "GET_ALL_WITHDRAW_ERROR",
  ADD_WITHDRAW_START: "ADD_WITHDRAW_START",
  ADD_WITHDRAW_SUCCESS: "ADD_WITHDRAW_SUCCESS",
  ADD_WITHDRAW_ERROR: "ADD_WITHDRAW_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_WITHDRAW_LIST: "REFRESH_WITHDRAW_LIST",
  SET_WITHDRAW_BATCH_NUMBER: "SET_WITHDRAW_BATCH_NUMBER",
  SELECT_WITHDRAW_DETAILS: "SELECT_WITHDRAW_DETAILS",
  GET_ALL_COUNTRY_LIST_START: "GET_ALL_COUNTRY_LIST_START",
  GET_ALL_COUNTRY_LIST_SUCCESS: "GET_ALL_COUNTRY_LIST_SUCCESS",
  GET_ALL_COUNTRY_LIST_ERROR: "GET_ALL_COUNTRY_LIST_ERROR",
  GET_ALL_GATEWAY_LIST_START: "GET_ALL_GATEWAY_LIST_START",
  GET_ALL_GATEWAY_LIST_SUCCESS: "GET_ALL_GATEWAY_LIST_SUCCESS",
  GET_ALL_GATEWAY_LIST_ERROR: "GET_ALL_GATEWAY_LIST_ERROR",
  UPDATE_WITHDRAW_START: "UPDATE_WITHDRAW_START",
  UPDATE_WITHDRAW_SUCCESS: "UPDATE_WITHDRAW_SUCCESS",
  UPDATE_WITHDRAW_ERROR: "UPDATE_WITHDRAW_ERROR",
  DELETE_WITHDRAW_START: "DELETE_WITHDRAW_START",
  DELETE_WITHDRAW_SUCCESS: "DELETE_WITHDRAW_SUCCESS",
  DELETE_WITHDRAW_ERROR: "DELETE_WITHDRAW_ERROR",
};

export const WithdrawManagementActions = {
  getAllWithdrawStart: (data) => ({
    type: WithdrawManagementMap.GET_ALL_WITHDRAW_START,
    payload: data,
  }),
  getAllWithdrawSuccess: (data) => ({
    type: WithdrawManagementMap.GET_ALL_WITHDRAW_SUCCESS,
    payload: data,
  }),
  getAllWithdrawError: (errors) => ({
    type: WithdrawManagementMap.GET_ALL_WITHDRAW_ERROR,
    payload: {
      errors
    },
  }),
  deleteWithdrawStart: (data) => ({
    type: WithdrawManagementMap.DELETE_WITHDRAW_START,
    payload: data,
  }),
  deleteWithdrawSuccess: (data) => ({
    type: WithdrawManagementMap.DELETE_WITHDRAW_SUCCESS,
    payload: data,
  }),
  deleteWithdrawError: (errors) => ({
    type: WithdrawManagementMap.DELETE_WITHDRAW_ERROR,
    payload: {
      errors
    },
  }),
  addWithdrawStart: (data) => ({
    type: WithdrawManagementMap.ADD_WITHDRAW_START,
    payload: data,
  }),
  addWithdrawSuccess: (data) => ({
    type: WithdrawManagementMap.ADD_WITHDRAW_SUCCESS,
    payload: data,
  }),
  addWithdrawError: (errors) => ({
    type: WithdrawManagementMap.ADD_WITHDRAW_ERROR,
    payload: {
      errors
    },
  }),
  updateWithdrawStart: (data) => ({
    type: WithdrawManagementMap.UPDATE_WITHDRAW_START,
    payload: data,
  }),
  updateWithdrawSuccess: (data) => ({
    type: WithdrawManagementMap.UPDATE_WITHDRAW_SUCCESS,
    payload: data,
  }),
  updateWithdrawError: (errors) => ({
    type: WithdrawManagementMap.UPDATE_WITHDRAW_ERROR,
    payload: {
      errors
    },
  }),
  getWithdrawCountryListStart: (data) => ({
    type: WithdrawManagementMap.GET_ALL_COUNTRY_LIST_START,
    payload: data,
  }),
  getWithdrawCountryListSuccess: (data) => ({
    type: WithdrawManagementMap.GET_ALL_COUNTRY_LIST_SUCCESS,
    payload: data,
  }),
  getWithdrawCountryListError: (errors) => ({
    type: WithdrawManagementMap.GET_ALL_COUNTRY_LIST_ERROR,
    payload: {
      errors
    },
  }),
  getWithdrawGatewayListStart: (data) => ({
    type: WithdrawManagementMap.GET_ALL_GATEWAY_LIST_START,
    payload: data,
  }),
  getWithdrawGatewayListSuccess: (data) => ({
    type: WithdrawManagementMap.GET_ALL_GATEWAY_LIST_SUCCESS,
    payload: data,
  }),
  getWithdrawGatewayListError: (errors) => ({
    type: WithdrawManagementMap.GET_ALL_GATEWAY_LIST_ERROR,
    payload: {
      errors
    },
  }),
  applyFilters: (data) => ({
    type: WithdrawManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshWithdrawList: () => ({
    type: WithdrawManagementMap.REFRESH_WITHDRAW_LIST
  }),
  searchTextChange: (data) => ({
    type: WithdrawManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedWithdraw: (data) => ({
    type: WithdrawManagementMap.SELECT_WITHDRAW_DETAILS,
    payload: data,
  }),
  setWithdrawBatchNumber: (data) => ({
    type: WithdrawManagementMap.SET_WITHDRAW_BATCH_NUMBER,
    payload: data,
  }),
  setSortEmail: (data) => ({
    type: WithdrawManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),
  updateWithdrawStatusStart: (data) => ({
    type: WithdrawManagementMap.UPDATE_WITHDRAW_START,
    payload: data,
  }),
  updateWithdrawStatusSuccess: (data) => ({
    type: WithdrawManagementMap.UPDATE_WITHDRAW_SUCCESS,
    payload: data,
  }),
  updateWithdrawStatusError: (data) => ({
    type: WithdrawManagementMap.UPDATE_WITHDRAW_ERROR,
    payload: data,
  })
};