import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal, Tab, Table, Tabs } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { UserManagementActions } from "../../redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

const UserManagementTable = ({ onUserDetailsClick }) => {
  const [UserModel, setUserModel] = useState(false);
  const deleteClose = () => {
    setUserModel(false);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [userData] = useState();
  const { isLoading, userList, skip, limit, dir, searchBy } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const handleSelect = (key) => {};

  useEffect(
    () => handleSort("", ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onUserDetailClick = (UserDetails) => {
    history.push(`/user-details/${UserDetails._id}`);
  };
  const onTradingHistoryClick = (UserDetails) => {
    history.push(`/user-trading-details/${UserDetails._id}`);
  };

  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(UserManagementActions.setUserBatchNumber(count));
  };

  const handleSort = (column, dir) => {
    dispatch(UserManagementActions.setSort({ column, dir }));
  };

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (userList) {
      setPageCount(Math.ceil(userList?.recordsTotal / 10));
      setCount(userList.recordsTotal);
    }
  }, [offset, pageCount, userList]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    dispatch(UserManagementActions.setUserBatchNumber(selectedPage));
  };
  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              id="myTable"
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "firstName",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    First Name{" "}
                    {dir !== "" && searchBy === "firstName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "lastName",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Last Name
                    {dir !== "" && searchBy === "lastName" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "email",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Email
                    {dir !== "" && searchBy === "email" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "phoneNumber",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Mobile
                    {dir !== "" && searchBy === "phoneNumber" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "country",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Country
                    {dir !== "" && searchBy === "country" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "isKycVerified",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    KYC
                    {dir !== "" && searchBy === "isKycVerified" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userList.records && userList.records.length ? (
                  userList.records.map((user, index) => {
                    return (
                      <tr
                        key={index}
                        // style={{ cursor: "pointer" }}
                        // onClick={(e) => onUserDetailClick(user)}
                      >
                        <td>
                          <span className="">{skip * limit + (index + 1)}</span>
                        </td>
                        <td>{user.firstName || "-"}</td>
                        <td>{user.lastName || "-"}</td>
                        <td>{user.email || "-"}</td>
                        <td>{user.phoneNumber || "-"}</td>
                        <td>{user.country || "-"}</td>
                        <td>
                          <span
                            style={{
                              color:
                                user?.isKycVerified === true
                                  ? "#13ad13"
                                  : "#ff1818",
                            }}
                          >
                            {user.isKycVerified === true
                              ? "Verified"
                              : "Not verified"}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex">
                            <button
                              title="Edit"
                              className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              onClick={(e) => onUserDetailClick(user)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Visible.svg"
                                  )}
                                />
                              </span>
                            </button>
                            <button
                              title="Delete"
                              className="btn btn-icon btn-light btn-hover-primary btn-sm"
                              onClick={(e) => onTradingHistoryClick(user)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Shopping/Wallet3.svg"
                                  )}
                                />
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center p-0 border-0">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {/* {!isLoading && (
            <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
              <div className="col-md-8 text-right mb-5 px-0 aspgntn">
                <BasicPagination
                  totalRecords={userList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_14 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {userList.recordsTotal} Records
              </div>
            </div>
          )} */}

          {/* {!isLoading && ( */}
          <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
            <div className="col-md-8 text-right mb-5 px-0 aspgntn">
              {count > 10 && pageCount > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  color="primary"
                  variant="outlined"
                />
              )}
            </div>
            <div className="col-md-4 mb-5 fn_s_14 text-right">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {userList.recordsTotal} Records
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      <Modal
        show={UserModel}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">
            User Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="basic-user-details"
            id="UserManagementTab"
            className=""
            onSelect={handleSelect}
          >
            <Tab eventKey="basic-user-details" title="Basic Details">
              <table className="mt-3">
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400">First Name: </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.firstName || "-"}
                    </span>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400 ">Last Name: </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.lastName || "-"}
                    </span>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400 ">Email: </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.email || "-"}
                    </span>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400 ">Mobile:</label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.phoneNumber || "-"}
                    </span>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400">Country: </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.country || "-"}
                    </span>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400">Joined: </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.createdAt
                        ? moment(userData.createdAt).format("MMM DD, YYYY")
                        : "-"}
                    </span>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400 ">Address: </label>
                  </td>
                  <td>
                    <div className="ml-5">
                      <span className="color_main f-w-850 text-capitalize">
                        {userData?.address || "-"}
                      </span>
                    </div>
                  </td>
                </tr>
                {userData?.documentImage && (
                  <tr>
                    <td className="table-cell">
                      <label className="fn_s_16 f-w-400">
                        KYC
                        <br />
                        Submitted{" "}
                      </label>
                    </td>
                    <td className="table-cell">
                      <span className="color_main ml-0">
                        {userData?.documentName || "-"}
                        <a href={userData?.documentImage} download>
                          <button
                            style={{
                              fontSize: "11px",
                              width: "auto",
                              height: "auto",
                            }}
                            className="btn_new mt-1 ml-5"
                            type="button"
                          >
                            Download
                          </button>
                        </a>
                      </span>
                    </td>
                  </tr>
                )}
              </table>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm mt-6"
                    type="button"
                    onClick={deleteClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Tab>
            {/* 2 Tab  */}
            <Tab eventKey="bank-details" title="Bank Details">
              <table className="mt-5">
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400">Bank Name : </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-4">
                      {userData?.bankDetail.name || "-"}
                    </span>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400">
                      Bank Account Number :{" "}
                    </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-4">
                      {userData?.bankDetail.account || "-"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="fn_s_16 f-w-400">IFSC Code : </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-4">
                      {userData?.bankDetail.bankCode || "-"}
                    </span>
                  </td>
                </tr>
              </table>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm mt-5"
                    type="button"
                    onClick={deleteClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Tab>
            {/* 3 Tab  */}
            <Tab eventKey="KYC-details" title="KYC Details">
              <table className="mt-5">
                <tr className="border-bottom">
                  <td>
                    <label className="fn_s_16 f-w-400">IsKycVerified:</label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.isKycVerified === true ? " Yes" : " No"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="fn_s_16 f-w-400">
                      IsDocumentVerified:
                    </label>
                  </td>
                  <td>
                    <span className="color_main f-w-850 ml-0">
                      {userData?.isDocumentVerified === true ? " Yes" : " No"}
                    </span>
                  </td>
                </tr>
              </table>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm mt-5"
                    type="button"
                    onClick={deleteClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserManagementTable;
