import { DisputeMap } from "./DisputeAction";

const initialState = {
  isLoading: false,
  DISPUTEList: {},
  refreshDISPUTEList: true,
  selectedDISPUTE: null,
  searchBy: "",
  dir: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
  disputeDetails: {},
  logDetails: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DisputeMap.GET_ALL_DISPUTE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DisputeMap.GET_ALL_DISPUTE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        DISPUTEList: action.payload,
        refreshDISPUTEList: false,
      };
    }
    case DisputeMap.GET_ALL_DISPUTE_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDISPUTEList: false,
      };
    }
    case DisputeMap.ADD_DISPUTE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DisputeMap.ADD_DISPUTE_SUCCESS: {
      return {
        ...state,
        refreshDISPUTEList: true,
      };
    }
    case DisputeMap.ADD_DISPUTE_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDISPUTEList: false,
      };
    }
    case DisputeMap.UPDATE_DISPUTE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DisputeMap.UPDATE_DISPUTE_SUCCESS: {
      return {
        ...state,
        refreshDISPUTEList: true,
      };
    }
    case DisputeMap.UPDATE_DISPUTE_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDISPUTEList: false,
      };
    }
    case DisputeMap.GET_ALL_TEMPLATES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DisputeMap.GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templates: action.payload,
        refreshDISPUTEList: true,
      };
    }
    case DisputeMap.GET_ALL_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        templates: [],
        refreshDISPUTEList: false,
      };
    }
    case DisputeMap.SELECT_DISPUTE_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedDISPUTE: action.payload,
      };
    }
    case DisputeMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case DisputeMap.SET_DISPUTE_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshDISPUTEList: true,
      };
    }
    case DisputeMap.REFRESH_DISPUTE_LIST: {
      return {
        ...state,
        skip: 0,
        refreshDISPUTEList: true,
      };
    }
    case DisputeMap.DELETE_DISPUTE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DisputeMap.DELETE_DISPUTE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshDISPUTEList: true,
      };
    }
    case DisputeMap.DELETE_DISPUTE_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDISPUTEList: false,
      };
    }
    case DisputeMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }

    case DisputeMap.GET_DISPUTE_DETAILS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DisputeMap.GET_DISPUTE_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        disputeDetails: action.payload,
      };
    }
    case DisputeMap.GET_DISPUTE_DETAILS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case DisputeMap.GET_LOG_DETAILS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DisputeMap.GET_LOG_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        logDetails: action.payload,
      };
    }
    case DisputeMap.GET_LOG_DETAILS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DisputeMap.SET_LOADER: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    default:
      return { ...state };
  }
};
