import { CMSManagementMap } from "./CMSManagementAction";

const initialState = {
  isLoading: false,
  CMSList: {},
  refreshCMSList: true,
  selectedCMS: null,
  searchBy: "",
  dir: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CMSManagementMap.GET_ALL_CMS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CMSManagementMap.GET_ALL_CMS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        CMSList: action.payload,
        refreshCMSList: false,
      };
    }
    case CMSManagementMap.GET_ALL_CMS_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCMSList: false,
      };
    }
    case CMSManagementMap.ADD_CMS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CMSManagementMap.ADD_CMS_SUCCESS: {
      return {
        ...state,
        refreshCMSList: true,
      };
    }
    case CMSManagementMap.ADD_CMS_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCMSList: false,
      };
    }
    case CMSManagementMap.UPDATE_CMS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CMSManagementMap.UPDATE_CMS_SUCCESS: {
      return {
        ...state,
        refreshCMSList: true,
      };
    }
    case CMSManagementMap.UPDATE_CMS_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCMSList: false,
      };
    }
    case CMSManagementMap.GET_ALL_TEMPLATES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CMSManagementMap.GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templates: action.payload,
        refreshCMSList: true,
      };
    }
    case CMSManagementMap.GET_ALL_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        templates: [],
        refreshCMSList: false,
      };
    }
    case CMSManagementMap.SELECT_CMS_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedCMS: action.payload,
      };
    }
    case CMSManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case CMSManagementMap.SET_CMS_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshCMSList: true,
      };
    }
    case CMSManagementMap.REFRESH_CMS_LIST: {
      return {
        ...state,
        skip: 0,
        refreshCMSList: true,
      };
    }
    case CMSManagementMap.DELETE_CMS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CMSManagementMap.DELETE_CMS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshCMSList: true,
      };
    }
    case CMSManagementMap.DELETE_CMS_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshCMSList: false,
      };
    }
    case CMSManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }
    default:
      return { ...state };
  }
};
