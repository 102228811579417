import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import { Form, Modal, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

import { toast } from "react-toastify";

import { getEthersData } from "../../../../utils/web3/functions/scMethods";
// import BasicPagination from "../../../pagination/BasicPagination";
import {
  getAllWithdrawAsync,
  updateWithdrawStatusAsync,
  WithdrawManagementActions,
} from "../../redux";
import environment from "../../../../utils/web3/constants/constants";
import moment from "moment";
import ReactPaginate from "react-paginate";

const WithdrawManagementTable = () => {
  const [withdrawStatus, setWithdrawStatus] = useState(false);
  const [reason, setReason] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [withdrawData, setWithdrawData] = useState();
  const { isLoading, WithdrawList, skip, limit, dir } = useSelector(
    (state) => state.withdrawManagement,
    shallowEqual
  );
  const [error, setError] = useState("");
  const [signature, setSignature] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const infoClose = () => setWithdrawStatus(false);
  const infoShow = () => setWithdrawStatus(true);
  const dispatch = useDispatch();
  const {
    searchBy,

    searchText,
  } = useSelector((state) => state.withdrawManagement, shallowEqual);
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(WithdrawManagementActions.setWithdrawBatchNumber(count));
    dispatch(getAllWithdrawAsync(searchBy, searchText, ""));
  };

  const handleSort = (column, dir) => {
    dispatch(WithdrawManagementActions.setSortEmail({ column, dir }));
  };

  useEffect(() => {
    handleSort("", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWithdrawDetailClick = async (WithdrawLists) => {
    if (WithdrawLists.status === "requested") {
      setIsLoader(true);

      let a = getEthersData();

      const contract = new ethers.Contract(
        environment[5].dev.poolAddress,
        environment[5].dev.poolABI,
        a.wallet
      );

      let hash = await contract.generateHash(
        WithdrawLists.walletAddress,
        WithdrawLists.tokenAddress,
        ethers.utils.parseUnits(
          WithdrawLists.amount.toString(),
          WithdrawLists.decimal
        )
      );

      let binaryAsciiData = ethers.utils.arrayify(hash);
      let sign = await a.wallet.signMessage(binaryAsciiData);
      setSignature(sign);
      setWithdrawData(WithdrawLists);
      if (sign && sign !== "") {
        infoShow();
      } else {
        toast.error("There is some issue with signature.");
      }

      setIsLoader(false);
    }
  };

  const handleChange = (e) => {
    setError("");
    setReason(e.target.value);
  };

  const isValid = (val) => {
    if (val.trim() === "" || val === undefined) {
      setError("Reason can not be null");
      return false;
    }
    if (val.trim().length < 10 || val.trim().length > 150) {
      setError("Reason must be atleast 10 or at max 150 character long.");
      return false;
    }
    setError("");
    return true;
  };

  function camelize(str) {
    if (str) {
      const first = str[0].toUpperCase();
      const rest = str.slice(1, str.length);
      return first + rest;
    }
  }

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (WithdrawList) {
      setPageCount(Math.ceil(WithdrawList?.recordsTotal / 10));
      setCount(WithdrawList.recordsTotal);
    }
  }, [offset, pageCount, WithdrawList]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    dispatch(WithdrawManagementActions.setWithdrawBatchNumber(selectedPage));
  };

  const closeModal = () => {
    setWithdrawStatus(false);
  };

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      {isLoader && (
        <div className="basic-verification-loader text-center">
          <CircularProgress />
        </div>
      )}
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "name",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Name
                    {dir !== "" && searchBy === "name" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "accountNumber",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Account Number
                    {dir !== "" && searchBy === "accountNumber" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "amount",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Amount{" "}
                    {dir !== "" && searchBy === "amount" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "currency",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Currency{" "}
                    {dir !== "" && searchBy === "currency" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "platform",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Platform
                    {dir !== "" && searchBy === "platform" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "reqDate",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Req Date
                    {dir !== "" && searchBy === "reqDate" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleSort(
                        "status",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Status
                    {dir !== "" && searchBy === "status" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {WithdrawList &&
                WithdrawList?.records &&
                WithdrawList?.records.length ? (
                  WithdrawList?.records.map((WithdrawLists, index) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => onWithdrawDetailClick(WithdrawLists)}
                    >
                      <td>
                        <span>{skip * limit + (index + 1)}</span>
                      </td>
                      <td>
                        {WithdrawLists.firstName} {WithdrawLists.lastName}
                      </td>
                      <td>{WithdrawLists.accountNumber}</td>
                      <td>{WithdrawLists.amount}</td>
                      <td>{WithdrawLists.tokenName}</td>
                      <td>{WithdrawLists.platform}</td>
                      <td>
                        {WithdrawLists?.createdAt
                          ? moment(WithdrawLists.createdAt).format(
                              "MMM DD, YYYY"
                            )
                          : "-"}
                      </td>
                      <td>{camelize(WithdrawLists.status)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
            <div className="col-md-8 mb-5 aspgntn">
              {count > 10 && pageCount > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  color="primary"
                  variant="outlined"
                />
              )}
            </div>
            <div className="col-md-4 mb-5 fn_s_14 text-right">
              Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
              {WithdrawList?.recordsTotal} Records
            </div>
          </div>
          {/* {!isLoading && (
            <div className="row d-flex align-items-center mr-0 ml-0 border-top pt-5">
              <div className="col-md-8 mb-5 aspgntn">
                <BasicPagination
                  totalRecords={WithdrawList?.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_14 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {WithdrawList?.recordsTotal} Records
              </div>
            </div>
          )} */}
        </div>
      </div>

      <Modal
        show={withdrawStatus}
        onHide={infoClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">
            Withdraw Info
          </Modal.Title>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {showReason ? (
            <>
              <label className="fn_s_16 w-100  mb-5">
                Reason : &ensp;
                <Form.Control
                  as="textarea"
                  value={reason}
                  aria-label="With textarea"
                  rows="3"
                  required
                  className="mw-100 p-3 mt-2"
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter reason of rejection"
                />
              </label>
              {error !== "" && <span style={{ color: "red" }}> {error} </span>}{" "}
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-5">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={() => {
                      const valid = isValid(reason);
                      if (valid) {
                        dispatch(
                          updateWithdrawStatusAsync(
                            withdrawData._id,
                            { status: "rejected", reason: reason },
                            setWithdrawData
                          )
                        );
                        infoClose();
                      }
                    }}
                  >
                    Confirm
                  </button>
                </div>
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={() => {
                      setError("");
                      setShowReason(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <table>
                <tbody>
                  <tr className="border-bottom">
                    <td>
                      <label className="fn_s_16 f-w-400">ID : </label>
                    </td>
                    <td>
                      <span className="color_main f-w-850 ml-0">
                        {withdrawData?._id}
                      </span>
                    </td>
                  </tr>
                  <tr className="border-bottom">
                    <td>
                      <label className="fn_s_16 f-w-400">
                        Account number :{" "}
                      </label>
                    </td>
                    <td>
                      <span className="color_main f-w-850 ml-0">
                        {withdrawData?.accountNumber}
                      </span>
                    </td>
                  </tr>
                  <tr className="border-bottom">
                    <td>
                      <label className="fn_s_16 f-w-400">Amount :</label>
                    </td>
                    <td>
                      <span className="color_main f-w-850 ml-0">
                        {withdrawData?.amount}
                      </span>
                    </td>
                  </tr>
                  <tr className="">
                    <td colSpan={2}>
                      <label className="fn_s_16">
                        Remarks :{" "}
                        <Form.Control
                          as="textarea"
                          value={withdrawData?.remarks}
                          aria-label="With textarea"
                          rows="5"
                          style={{
                            cursor: "default",
                            width: "29rem",
                            overflow: "auto",
                            fontSize: "14px",
                            height: "131px",
                          }}
                          disabled={true}
                          required
                          className="p-3 mt-4"
                          placeholder="Remarks"
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-5">
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={() => {
                      if (signature === "") {
                        toast.error("Please Wait till Signature generate.");
                      } else {
                        dispatch(
                          updateWithdrawStatusAsync(
                            withdrawData._id,
                            { status: "confirmed", signature: signature },
                            setWithdrawData
                          )
                        );
                        infoClose();
                      }
                    }}
                  >
                    Approve
                  </button>
                </div>
                <div className="form-group mr-5 mb-1 mt-2">
                  <button
                    className="btn_new btn-sm"
                    type="button"
                    onClick={() => {
                      setShowReason(true);
                    }}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WithdrawManagementTable;
