import supportedChains from "./chains";

export function ellipseAddress(address) {
  const width = 6;
  return `${address.slice(0, width)}...${address.slice(-4)}`;
}

export async function getChainData(chainId) {
  let chainData = [];
  chainData = await supportedChains.filter(
    (chain) => chain.chain_id === chainId
  );

  if (chainData && chainData.length) {
    const chainDetail = chainData[0];
    return chainDetail;
  }
  return {
    name: "",
    chain_id: "",
    network_id: 0,
    rpc_url: "",
    network_type: "",
    isChainValid: false,
  };
}
