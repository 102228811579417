export const DisputeMap = {
  GET_ALL_DISPUTE_START: "GET_ALL_DISPUTE_START",
  GET_ALL_DISPUTE_SUCCESS: "GET_ALL_DISPUTE_SUCCESS",
  GET_ALL_DISPUTE_ERROR: "GET_ALL_DISPUTE_ERROR",
  ADD_DISPUTE_START: "ADD_DISPUTE_START",
  ADD_DISPUTE_SUCCESS: "ADD_DISPUTE_SUCCESS",
  ADD_DISPUTE_ERROR: "ADD_DISPUTE_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  DISPUTE_FILTERS: "DISPUTE_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_DISPUTE_LIST: "REFRESH_DISPUTE_LIST",
  SET_DISPUTE_BATCH_NUMBER: "SET_DISPUTE_BATCH_NUMBER",
  SELECT_DISPUTE_DETAILS: "SELECT_DISPUTE_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  UPDATE_DISPUTE_START: "UPDATE_DISPUTE_START",
  UPDATE_DISPUTE_SUCCESS: "UPDATE_DISPUTE_SUCCESS",
  UPDATE_DISPUTE_ERROR: "UPDATE_DISPUTE_ERROR",
  DELETE_DISPUTE_START: "DELETE_DISPUTE_START",
  DELETE_DISPUTE_SUCCESS: "DELETE_DISPUTE_SUCCESS",
  DELETE_DISPUTE_ERROR: "DELETE_DISPUTE_ERROR",

  GET_DISPUTE_DETAILS_START: "GET_DISPUTE_DETAILS_START",
  GET_DISPUTE_DETAILS_SUCCESS: "GET_DISPUTE_DETAILS_SUCCESS",
  GET_DISPUTE_DETAILS_ERROR: "GET_DISPUTE_DETAILS_ERROR",

  GET_LOG_DETAILS_START: "GET_LOG_DETAILS_START",
  GET_LOG_DETAILS_SUCCESS: "GET_LOG_DETAILS_SUCCESS",
  GET_LOG_DETAILS_ERROR: "GET_LOG_DETAILS_ERROR",
  SET_LOADER: "SET_LOADER",
};

export const DISPUTEManagementActions = {
  getAllDISPUTEStart: (data) => ({
    type: DisputeMap.GET_ALL_DISPUTE_START,
    payload: data,
  }),
  getAllDISPUTESuccess: (data) => ({
    type: DisputeMap.GET_ALL_DISPUTE_SUCCESS,
    payload: data,
  }),
  getAllDISPUTEError: (errors) => ({
    type: DisputeMap.GET_ALL_DISPUTE_ERROR,
    payload: { errors },
  }),
  deleteDISPUTEStart: (data) => ({
    type: DisputeMap.DELETE_DISPUTE_START,
    payload: data,
  }),
  deleteDISPUTESuccess: (data) => ({
    type: DisputeMap.DELETE_DISPUTE_SUCCESS,
    payload: data,
  }),
  deleteDISPUTEError: (errors) => ({
    type: DisputeMap.DELETE_DISPUTE_ERROR,
    payload: { errors },
  }),
  addDISPUTEStart: (data) => ({
    type: DisputeMap.ADD_DISPUTE_START,
    payload: data,
  }),
  addDISPUTESuccess: (data) => ({
    type: DisputeMap.ADD_DISPUTE_SUCCESS,
    payload: data,
  }),
  addDISPUTEError: (errors) => ({
    type: DisputeMap.ADD_DISPUTE_ERROR,
    payload: { errors },
  }),
  updateDISPUTEStart: (data) => ({
    type: DisputeMap.UPDATE_DISPUTE_START,
    payload: data,
  }),
  updateDISPUTESuccess: (data) => ({
    type: DisputeMap.UPDATE_DISPUTE_SUCCESS,
    payload: data,
  }),
  updateDISPUTEError: (errors) => ({
    type: DisputeMap.UPDATE_DISPUTE_ERROR,
    payload: { errors },
  }),
  getDISPUTETemplateStart: (data) => ({
    type: DisputeMap.GET_ALL_TEMPLATES_START,
    payload: data,
  }),
  getDISPUTETemplateSuccess: (data) => ({
    type: DisputeMap.GET_ALL_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getDISPUTETemplateError: (errors) => ({
    type: DisputeMap.GET_ALL_TEMPLATES_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: DisputeMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshDISPUTEList: () => ({ type: DisputeMap.REFRESH_DISPUTE_LIST }),
  searchTextChange: (data) => ({
    type: DisputeMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedDISPUTE: (data) => ({
    type: DisputeMap.SELECT_DISPUTE_DETAILS,
    payload: data,
  }),
  setDISPUTEBatchNumber: (data) => ({
    type: DisputeMap.SET_DISPUTE_BATCH_NUMBER,
    payload: data,
  }),
  setSortDISPUTE: (data) => ({
    type: DisputeMap.SET_SORT_CHANGE,
    payload: data,
  }),

  getDesputeDetailsStart: (data) => ({
    type: DisputeMap.GET_DISPUTE_DETAILS_START,
    payload: data,
  }),
  getDesputeDetailsSuccess: (data) => ({
    type: DisputeMap.GET_DISPUTE_DETAILS_SUCCESS,
    payload: data,
  }),
  getDesputeDetailsError: (errors) => ({
    type: DisputeMap.GET_DISPUTE_DETAILS_ERROR,
    payload: { errors },
  }),

  getLogDetailsStart: (data) => ({
    type: DisputeMap.GET_LOG_DETAILS_START,
    payload: data,
  }),
  getLogDetailsSuccess: (data) => ({
    type: DisputeMap.GET_LOG_DETAILS_SUCCESS,
    payload: data,
  }),
  getLogDetailsError: (errors) => ({
    type: DisputeMap.GET_LOG_DETAILS_ERROR,
    payload: { errors },
  }),
  setLoader: (data) => ({
    type: DisputeMap.SET_LOADER,
    payload: data,
  }),
};
