import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { PaymentManagementActions } from "./PaymentManagementAction";

export const getAllPAYMENTAsync = (searchBy, searchText, dir) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().paymentManagement;
      dispatch(PaymentManagementActions.getAllPAYMENTStart());

      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}/api/paymentGateway/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          PaymentManagementActions.getAllPAYMENTSuccess(data.data)
        );
      }
      dispatch(PaymentManagementActions.getAllPAYMENTError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentManagementActions.getAllPAYMENTError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
export const addPAYMENTAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(PaymentManagementActions.addPAYMENTStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/paymentGateway/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(PaymentManagementActions.addPAYMENTSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(PaymentManagementActions.addPAYMENTError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentManagementActions.addPAYMENTError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updatePAYMENTAsync = (
  values,
  setSubmitting,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      let { selectedPAYMENT } = getState().paymentManagement;
      dispatch(PaymentManagementActions.updatePAYMENTStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}/api/paymentGateway/updateDetails/${selectedPAYMENT._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.code === 200) {
        dispatch(PaymentManagementActions.updatePAYMENTSuccess(data.data));
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(PaymentManagementActions.updatePAYMENTError());
      setSubmitting(false);
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentManagementActions.updatePAYMENTError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deletePAYMENTAsync = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(PaymentManagementActions.deletePAYMENTStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/paymentGateway/delete/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        dispatch(PaymentManagementActions.deletePAYMENTSuccess(data.data));
        return dispatch(showSuccessSnackbar("success", data.message, 3000));
      }
      dispatch(PaymentManagementActions.deletePAYMENTError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(PaymentManagementActions.deletePAYMENTError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
