import { StatisticsWidget1 } from "../../../_metronic/_partials/widgets";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getTotalUserCount } from "../userManagement/redux";

export function StatisticsWidget() {
  const dispatch = useDispatch();

  const { totalUser } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getTotalUserCount());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-xl-3 pb-5">
        <StatisticsWidget1
          className="card-xl-stretch ivt_card shadow_1"
          svgIcon="/media/svg/icons/General/User.svg"
          // color="light"
          iconColor="text-blue"
          titleText="USERS :"
          description={`${totalUser.recordsTotal || 0} `}
          redirectUrl="/users-management/users"
        />
      </div>
    </div>
  );
}
