import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { CONTACTManagementActions, getContactUS } from "../redux";
import ContactUsTable from "./countryManagementDetail/contactUsTable";

export function ContactUs() {
  const dispatch = useDispatch();
  const searchContactRef = useRef();
  const { refreshContactList, searchBy, searchText, dir } = useSelector(
    (state) => state.contactUs,
    shallowEqual
  );
  useEffect(() => {
    dispatch(CONTACTManagementActions.setSelectedCONTACT(null));
    if (searchContactRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(CONTACTManagementActions.refreshCONTACTList());
        }
      };
      let input = searchContactRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(CONTACTManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);
  useEffect(() => {
    if (refreshContactList) {
      dispatch(getContactUS(searchBy, searchText));
    }
    dispatch(getContactUS(searchBy, searchText, dir));
  }, [dispatch, refreshContactList, searchBy, searchText, dir]);

  const onSearchTextChange = (e) => {
    dispatch(CONTACTManagementActions.searchTextChange(e.target.value));
  };
  return (
    <>
      <Card>
        <CardHeader title="ContactUs">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchContactRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <ContactUsTable />
      </Card>
    </>
  );
}
