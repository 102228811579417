import { DepositManagementMap } from "./DepositManagementAction";

const initialState = {
  isLoading: false,
  DepositList: {},
  refreshDepositList: true,
  selectedDeposit: null,
  searchBy: "",
  dir: "",
  templates: [],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DepositManagementMap.GET_ALL_DEPOSIT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DepositManagementMap.GET_ALL_DEPOSIT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        DepositList: action.payload,
        refreshDepositList: false,
      };
    }
    case DepositManagementMap.GET_ALL_DEPOSIT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDepositList: false,
      };
    }
    case DepositManagementMap.ADD_DEPOSIT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DepositManagementMap.ADD_DEPOSIT_SUCCESS: {
      return {
        ...state,
        refreshDepositList: true,
      };
    }
    case DepositManagementMap.ADD_DEPOSIT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDepositList: false,
      };
    }
    case DepositManagementMap.UPDATE_DEPOSIT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DepositManagementMap.UPDATE_DEPOSIT_SUCCESS: {
      return {
        ...state,
        refreshDepositList: true,
      };
    }
    case DepositManagementMap.UPDATE_DEPOSIT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDepositList: false,
      };
    }
    case DepositManagementMap.GET_ALL_TEMPLATES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DepositManagementMap.GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templates: action.payload,
        refreshDepositList: true,
      };
    }
    case DepositManagementMap.GET_ALL_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        templates: [],
        refreshDepositList: false,
      };
    }
    case DepositManagementMap.SELECT_DEPOSIT_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedDeposit: action.payload,
      };
    }
    case DepositManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case DepositManagementMap.SET_DEPOSIT_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshDepositList: true,
      };
    }
    case DepositManagementMap.REFRESH_DEPOSIT_LIST: {
      return {
        ...state,
        skip: 0,
        refreshDepositList: true,
      };
    }
    case DepositManagementMap.DELETE_DEPOSIT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DepositManagementMap.DELETE_DEPOSIT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshDepositList: true,
      };
    }
    case DepositManagementMap.DELETE_DEPOSIT_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshDepositList: false,
      };
    }
    case DepositManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }
    default:
      return { ...state };
  }
};
