import axios from "axios";
import { string } from "prop-types";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { OrderManagementActions } from "./OrderManagementAction";

export const getAllOrderAsync = (searchBy, searchText, dir, orderFilter) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().OrderManagement;
      dispatch(OrderManagementActions.getAllOrderStart());
      const { data } = await axios({
        method: "Post",
        url: `${process.env.REACT_APP_HOST}/api/order/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}`,
        data: { ...orderFilter },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(OrderManagementActions.getAllOrderSuccess(data.data));
      }
      dispatch(OrderManagementActions.getAllOrderError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(OrderManagementActions.getAllOrderError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
export const getAllOrderCountryListsAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(OrderManagementActions.getOrderCountryListStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/user/getCountry`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          OrderManagementActions.getOrderCountryListSuccess(data.data)
        );
      }
      dispatch(OrderManagementActions.getOrderCountryListError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(OrderManagementActions.getOrderCountryListError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getAllOrderGatewayListsAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(OrderManagementActions.getOrderGatewayListStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/paymentGateway/getPayment`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          OrderManagementActions.getOrderGatewayListSuccess(data.data)
        );
      }
      dispatch(OrderManagementActions.getOrderGatewayListError());

      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(OrderManagementActions.getOrderGatewayListError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getOrderDetailByIdAsync = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(OrderManagementActions.getOrderDetailStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/order/adminOrders/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return await dispatch(
          OrderManagementActions.getOrderDetailSuccess(data.data)
        );
      }
      dispatch(OrderManagementActions.getOrderDetailError());
    } catch (error) {
      dispatch(OrderManagementActions.getOrderDetailError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
