import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { COUNTRYManagementActions, getAllCOUNTRYAsync } from "../redux";
import CountryManagementTable from "./countryManagementDetail/countryManagementTable";

export function CountryManagement() {
  const dispatch = useDispatch();
  const searchCountryRef = useRef();
  const { refreshCOUNTRYList, searchBy, searchText, dir } = useSelector(
    (state) => state.CountryManagement,
    shallowEqual
  );
  useEffect(() => {
    dispatch(COUNTRYManagementActions.setSelectedCOUNTRY(null));
    if (searchCountryRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(COUNTRYManagementActions.refreshCOUNTRYList());
        }
      };
      let input = searchCountryRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(COUNTRYManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);
  useEffect(() => {
    if (refreshCOUNTRYList) {
      dispatch(getAllCOUNTRYAsync(searchBy, searchText));
    }
    dispatch(getAllCOUNTRYAsync(searchBy, searchText, dir));
  }, [dispatch, refreshCOUNTRYList, searchBy, searchText, dir]);

  const onSearchTextChange = (e) => {
    dispatch(COUNTRYManagementActions.searchTextChange(e.target.value));
  };
  return (
    <>
      <Card>
        <CardHeader title="Country">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchCountryRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CountryManagementTable />
      </Card>
    </>
  );
}
