import { PaymentMasterMap } from "./paymentMasterManagementAction";

const initialState = {
  isLoading: false,
  paymentmasterlist: {},
  refreshpaymentmasterlist: true,
  selectedpaymentmasterlist: null,
  searchBy: "",
  dir: "",
  templates: [],
  gateway:[],
  searchText: "",
  skip: 0,
  limit: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentMasterMap.GET_ALL_PAYMENTMASTER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentMasterMap.GET_ALL_PAYMENTMASTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        paymentmasterlist: action.payload,
        refreshpaymentmasterlist: false,
      };
    }
    case PaymentMasterMap.GET_ALL_PAYMENTMASTER_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshpaymentmasterlist: false,
      };
    }
    case PaymentMasterMap.ADD_PAYMENTMASTER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentMasterMap.ADD_PAYMENTMASTER_SUCCESS: {
      return {
        ...state,
        refreshpaymentmasterlist: true,
      };
    }
    case PaymentMasterMap.ADD_PAYMENTMASTER_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshpaymentmasterlist: false,
      };
    }
    case PaymentMasterMap.UPDATE_PAYMENTMASTER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentMasterMap.UPDATE_PAYMENTMASTER_SUCCESS: {
      return {
        ...state,
        refreshpaymentmasterlist: true,
      };
    }
    case PaymentMasterMap.UPDATE_PAYMENTMASTER_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshpaymentmasterlist: false,
      };
    }
    case PaymentMasterMap.GET_ALL_TEMPLATES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentMasterMap.GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        templates: action.payload,
        refreshpaymentmasterlist: true,
      };
    }
    case PaymentMasterMap.GET_ALL_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        templates: [],
        refreshpaymentmasterlist: false,
      };
    }
    //
    case PaymentMasterMap.GET_ALL_GATEWAY_TEMPLATES_START: {
      return {
        ...state,
        gateway: action.payload,
        isLoading: true,
      };
    }
    case PaymentMasterMap.GET_ALL_GATEWAY_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        gateway: action.payload,
        refreshpaymentmasterlist: true,
      };
    }
    case PaymentMasterMap.GET_ALL_GATEWAY_TEMPLATES_ERROR: {
      return {
        ...state,
        isLoading: false,
        gateway: [],
        refreshpaymentmasterlist: false,
      };
    }
    //
    case PaymentMasterMap.SELECT_PAYMENTMASTER_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedpaymentmasterlist: action.payload,
      };
    }
    case PaymentMasterMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case PaymentMasterMap.SET_PAYMENTMASTER_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshpaymentmasterlist: true,
      };
    }
    case PaymentMasterMap.REFRESH_PAYMENTMASTER_LIST: {
      return {
        ...state,
        skip: 0,
        refreshpaymentmasterlist: true,
      };
    }
    case PaymentMasterMap.DELETE_PAYMENTMASTER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentMasterMap.DELETE_PAYMENTMASTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshpaymentmasterlist: true,
      };
    }
    case PaymentMasterMap.DELETE_PAYMENTMASTER_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshpaymentmasterlist: false,
      };
    }
    case PaymentMasterMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
      };
    }
    default:
      return { ...state };
  }
};
