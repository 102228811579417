import axios from "axios";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";
import { CONTACTManagementActions } from "./ContactUsAction";

export const getContactUS = (searchBy, searchText, dir) => {
  return async (dispatch, getState) => {
    try {
      let { skip, limit } = getState().contactUs;
      dispatch(CONTACTManagementActions.getAllCONTACTStart());

      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}/api/contactUs/getAll?skip=${skip}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(
          CONTACTManagementActions.getAllCONTACTSuccess(data.data)
        );
      }
      dispatch(CONTACTManagementActions.getAllCONTACTError());
      return dispatch(showSuccessSnackbar("error", data.message, 3000));
    } catch (error) {
      dispatch(CONTACTManagementActions.getAllCONTACTError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
