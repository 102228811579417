export const CountryManagementMap = {
  GET_ALL_COUNTRY_START: "GET_ALL_COUNTRY_START",
  GET_ALL_COUNTRY_SUCCESS: "GET_ALL_COUNTRY_SUCCESS",
  GET_ALL_COUNTRY_ERROR: "GET_ALL_COUNTRY_ERROR",
  ADD_COUNTRY_START: "ADD_COUNTRY_START",
  ADD_COUNTRY_SUCCESS: "ADD_COUNTRY_SUCCESS",
  ADD_COUNTRY_ERROR: "ADD_COUNTRY_ERROR",
  SELECT_USER_DETAILS: "SELECT_USER_DETAILS",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  REFRESH_COUNTRY_LIST: "REFRESH_COUNTRY_LIST",
  SET_COUNTRY_BATCH_NUMBER: "SET_COUNTRY_BATCH_NUMBER",
  SELECT_COUNTRY_DETAILS: "SELECT_COUNTRY_DETAILS",
  GET_ALL_TEMPLATES_START: "GET_ALL_TEMPLATES_START",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_ERROR: "GET_ALL_TEMPLATES_ERROR",
  UPDATE_COUNTRY_START: "UPDATE_COUNTRY_START",
  UPDATE_COUNTRY_SUCCESS: "UPDATE_COUNTRY_SUCCESS",
  UPDATE_COUNTRY_ERROR: "UPDATE_COUNTRY_ERROR",
  DELETE_COUNTRY_START: "DELETE_COUNTRY_START",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_ERROR: "DELETE_COUNTRY_ERROR",
};

export const COUNTRYManagementActions = {
  getAllCOUNTRYStart: (data) => ({
    type: CountryManagementMap.GET_ALL_COUNTRY_START,
    payload: data,
  }),
  getAllCOUNTRYSuccess: (data) => ({
    type: CountryManagementMap.GET_ALL_COUNTRY_SUCCESS,
    payload: data,
  }),
  getAllCOUNTRYError: (errors) => ({
    type: CountryManagementMap.GET_ALL_COUNTRY_ERROR,
    payload: { errors },
  }),
  deleteCOUNTRYStart: (data) => ({
    type: CountryManagementMap.DELETE_COUNTRY_START,
    payload: data,
  }),
  deleteCOUNTRYSuccess: (data) => ({
    type: CountryManagementMap.DELETE_COUNTRY_SUCCESS,
    payload: data,
  }),
  deleteCOUNTRYError: (errors) => ({
    type: CountryManagementMap.DELETE_COUNTRY_ERROR,
    payload: { errors },
  }),
  addCOUNTRYStart: (data) => ({
    type: CountryManagementMap.ADD_COUNTRY_START,
    payload: data,
  }),
  addCOUNTRYSuccess: (data) => ({
    type: CountryManagementMap.ADD_COUNTRY_SUCCESS,
    payload: data,
  }),
  addCOUNTRYError: (errors) => ({
    type: CountryManagementMap.ADD_COUNTRY_ERROR,
    payload: { errors },
  }),
  updateCOUNTRYStart: (data) => ({
    type: CountryManagementMap.UPDATE_COUNTRY_START,
    payload: data,
  }),
  updateCOUNTRYSuccess: (data) => ({
    type: CountryManagementMap.UPDATE_COUNTRY_SUCCESS,
    payload: data,
  }),
  updateCOUNTRYError: (errors) => ({
    type: CountryManagementMap.UPDATE_COUNTRY_ERROR,
    payload: { errors },
  }),
  getCOUNTRYTemplateStart: (data) => ({
    type: CountryManagementMap.GET_ALL_TEMPLATES_START,
    payload: data,
  }),
  getCOUNTRYTemplateSuccess: (data) => ({
    type: CountryManagementMap.GET_ALL_TEMPLATES_SUCCESS,
    payload: data,
  }),
  getCOUNTRYTemplateError: (errors) => ({
    type: CountryManagementMap.GET_ALL_TEMPLATES_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: CountryManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshCOUNTRYList: () => ({ type: CountryManagementMap.REFRESH_COUNTRY_LIST }),
  searchTextChange: (data) => ({
    type: CountryManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  setSelectedCOUNTRY: (data) => ({
    type: CountryManagementMap.SELECT_COUNTRY_DETAILS,
    payload: data,
  }),
  setCOUNTRYBatchNumber: (data) => ({
    type: CountryManagementMap.SET_COUNTRY_BATCH_NUMBER,
    payload: data,
  }),
  setSortCountry: (data) => ({
    type: CountryManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),
};
