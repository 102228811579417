import React from "react";
import { Link, Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ForgotPassword from "./ForgotPassword";
import InstructionsSent from "./InstructionsSent";
import Login from "./Login";
import SetNewPassword from "./SetNewPassword";
import Verification from "./Verification";

export function AuthPage() {
  const appName = process.env.REACT_APP_NAME;
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-4 p-lg-5"
          // style={{
          //   backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
          // }}
        >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between align-item-center ">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-auto text-center logo-auth">
                <img
                  alt="Logo"
                  // className="max-h-70px"
                  width="350px"
                  src={toAbsoluteUrl("/media/logos/image2.svg")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="d-flex flex-column justify-content-center text-center text-white">
                <h3 className="font-size-h1 mb-5">Welcome to {appName}</h3>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none d-lg-flex justify-content-center mt-auto">
                <div className="opacity-70 font-weight-bold text-white	text-center">
                  &copy; {new Date().getFullYear()} {appName}
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute
                  path="/auth/login"
                  component={Login}
                  title="Login"
                />
                <ContentRoute
                  path="/auth/verification"
                  component={Verification}
                  title="Verification"
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                  title="Forgot Password"
                />
                <ContentRoute
                  path="/auth/reset-password/:passwordToken?"
                  component={SetNewPassword}
                  title="Reset Password"
                />
                <ContentRoute
                  path="/auth/instructions-sent"
                  component={InstructionsSent}
                  title="Sent Instructions"
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {new Date().getFullYear()} {appName} </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
